<template>
	<!-- <button @click="getTemplates" type="button">Get Templates</button> -->
	<!--Why tf doesnt this work ???-->
	<!-- Update: it does work in HomeView.vue -->
	<div>
		<LoadingOverlay :message="message" />
	</div>
	<div class="highlight">
		<h1 id="instructions">{{ $t("Opened") }}</h1>
		<div class="templateButtons">
			<div>
				<button @click="showTemplateModal" type="button" class="myTemplatesButton">{{ $t("formview.MyTemplates")}}</button>
				<teleport to="body">
					<div class="modalTemplates" v-show="templateModalOpen" @click="handleClick">
						<div class="modalViewTemplates">
							<templateModal v-show="templateModalOpen" @close="closeTemplateModal" :templates="templates"
								@apply-template="applyTemplate" @apply-manual-template="applyManualTemplate"
								@remove-template="removeTemplate" />
						</div>
					</div>
				</teleport>
			</div>
			<div>
				<button @click="showTemplateNameModal" type="button" class="saveTemplateButton"
					:disabled="isSaveTemplateDisabled"
					:style="{ background: isSaveTemplateDisabled ? '#ccc' : '#2156A4' }">{{ $t("formview.SaveTemplate")}}</button>
				<teleport to="body">
					<div class="modalNameTemplates" v-show="templateNameModalOpen" @click="handleClick">
						<div class="modalViewNameTemplates">
							<templateNameModal v-show="templateNameModalOpen" @close="closeTemplateNameModal"
								:templateNames="templateNames" :content="randomData" @save-template="grabValues" />
						</div>
					</div>
				</teleport>
				
			</div>
		</div>
	</div>
	<!-- <div v-for="(field, index) in $root.anonymizedFields" :key="index" class="formSection">
        <div @click="field.show = !field.show" class="width100">
            <h2>{{ $t(field.label) }}</h2><img v-if="!field.show" src="../assets/plus.svg"><img v-else src="../assets/minus.svg">
        </div>
        <div v-if="field.show" class="formFields">
            <div v-for="(field, index) in field.parameters" :key="index" class="formField">
                <h3 class="formLabel">{{ $t(String(field.label)) }}</h3>
                <input class="formInput" 
                    autocomplete="off"
                    v-model="field.input" 
                    :id="field.id" 
                    :type="field.type" 
                    :description="field.helpPhrase"
                />
            </div>
        </div>
    </div> -->
	<div class="formSection">
		<div class="width100">
			<h2>{{ $t("ResultViewButtons.AnyData") }}</h2>
		</div>
		<div v-if="showRandomData" class="formFields">
			<div class="formField" v-for="(input, index) in randomData" :key="index">
				<div class="inputType">
					<div class="inputSelect" :id="input.key" @click="activateSelect(input.key)">
						<div class="selected-option">
							{{ input.selectedOption.text }}
						</div>
						<font-awesome-icon icon="caret-down" :class="{ 'rotate-icon': input.activeSelect }"
							class="triangle-btn" />

						<div class="options-container" v-if="input.activeSelect === true">
							<div class="option" v-for="option in options" :value="option.value" :key="option.value"
								@click="
									selectOption(
										option.text,
										option.value,
										input.value,
										input.key,
										input.key2,
										option.type
									)
									">
								{{ option.text }}
							</div>
						</div>
					</div>
				</div>
				<input @input="validate(input.value, input.selectedOption.value, input.key, input.key2)"
					class="formInput" autocomplete="off" v-show="datePickerHide(input.selectedOption.type)"
					v-model="input.value" :id="input.key2" :type="input.selectedOption.type"
					:description="$t('ResultViewButtons.AnyDataWrite')" />

				<!-- <VueDatePicker :key="input.key3" v-show="datePickerShow(input.selectedOption.type)"  v-model="date" :format="formatDate"></VueDatePicker> -->

				<img class="addRemoveFormButtons" @click="removeFormField(index)" v-if="index !== 0" :src="minusSvg" />
				<img class="addRemoveFormButtons" @click="addFormField()" v-if="index === 0" :src="plusSvg" />
			</div>
		</div>
	</div>
	<div class="formButtons">
		<!-- <div v-if="!disableButton" id="ocr">
			<div class="inlineocr">
				<div id="ocrRadio" v-for="option in ocrOptions">
					<input class="ocrInput" type="radio" :value="option.value" v-model="ocrprocess" :id="option.value">
					<label class="ocrLabel" :for="option.value" id="radio">{{ option.text }}</label>
				</div>
			</div>
			<div id="ocrStatusBlack">
				<p>{{ $t("formview.NumberOfDoc") }} {{ this.ocrCurrentProcesses }}/{{ this.ocrCombinedCap }}</p>
			</div>
		</div>
		<div v-else id="ocrFull">
			<div class="inlineocr">
				<div id="ocrRadio" v-for="option in ocrOptions">
					<input class="ocrInput" type="radio" :value="option.value" v-model="ocrprocess" :id="option.value">
					<label class="ocrLabel" :for="option.value">{{ option.text }}</label>
				</div>
			</div>
			<div id="ocrStatusRed">
				<p>{{ $t("formview.NumberOfDoc") }} {{ this.ocrCurrentProcesses }}/{{ this.ocrCombinedCap }}</p>
				<img class="infoPng" src="../assets/info_red_button_icon_227838.png" alt="" :title='$t("formview.OcrIsFull")'>
			</div>
			
		</div> -->
		<div :id="disableButton ? 'ocrFull' : 'ocr'">
			<div class="inlineocr">
				<div id="ocrRadio" v-for="option in ocrOptions" :key="option.value">
					<input class="ocrInput" type="radio" :value="option.value" v-model="ocrprocess" :id="option.value">
					<label class="ocrLabel" :for="option.value">{{ option.text }}</label>
				</div>
			</div>
			<div :id="disableButton ? 'ocrStatusRed' : 'ocrStatusBlack'">
				<p>{{ $t("formview.NumberOfDoc") }} {{ ocrCurrentProcesses }}/{{ ocrCombinedCap }}</p>
				<img v-if="disableButton" class="infoPng" src="../assets/info_red_button_icon_227838.png" alt=""
					:title='$t("formview.OcrIsFull")'>
			</div>
		</div>
		<div>
			<div class="ocrDescription" @click="showOcrHelpModal">Čo je OCR?</div>
			<OcrHelpModal v-show="ocrHelpModalOpen" @close="closeOcrHelpModal"/>
		</div>
		<div class="button-box">
			<div>
				<button @click="sendFilesToAnonyma(false, false)" class="confirmButton" :disabled="disableButton">
					{{ $t("ResultViewButtons.ConfirmButton") }}
				</button>
			</div>
			<div>
				<button @click="sendFilesToAnonyma(true, false)" class="secondary_btn" :disabled="shouldDisableButton">
					{{ $t("ResultViewButtons.manualAnotation") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import EnvProvider from "jvjr-docker-env";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import validator from "../components/validator/validator";
import '../../node_modules/pdfjs-dist/build/pdf.js'
import { AnnotationFactory } from '../../public/pdfAnnotate.js'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist'
import { ref } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import templateModal from "../components/templatesModal.vue"
import templateNameModal from "../components/templateNameModal.vue";
import { v4 as uuidv4 } from "uuid";
import OcrHelpModal from "../components/ocrHelpModal.vue";

export default {
	name: "FormView",
	components: { LoadingOverlay, VueDatePicker, templateModal, templateNameModal, OcrHelpModal },
	watch: {
		'$i18n.locale': function () {
			for (var input of this.randomData) {
				input['selectedOption']['text'] = this.$t('formview.' + input['selectedOption']['value'])
			}
			this.options = [
				{ value: "Any Data", text: this.$t("formview.AnyInput"), type: "text" },
				{ value: "Date", text: this.$t("formview.Date"), type: "text" },
				{ value: "Name", text: this.$t("formview.Name"), type: "text" },
				{ value: "Number", text: this.$t("formview.Number"), type: "text" },
				{ value: "Birth number", text: this.$t("formview.Birth number"), type: "text" },
				{ value: "E-mail", text: this.$t("formview.E-mail"), type: "text" },
				{ value: "Phone number", text: this.$t("formview.Phone number"), type: "text" },
				{ value: "Postal code", text: this.$t("formview.Postal code"), type: "text" },
				{ value: "ID number", text: this.$t("formview.ID number"), type: "text" },
				{ value: "Passport number", text: this.$t("formview.Passport number"), type: "text" },
				{ value: "IBAN number", text: this.$t("formview.IBAN number"), type: "text" },
				// { value: "Datum-2", text: "Dátum-2", type: "date-custom" },
			]
		}
	},
	setup() {
		const { cookies } = useCookies();

		const date = ref(new Date());

		const formatDate = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();

			return `Selected date is ${day}/${month}/${year}`;
		};

		return {
			cookies,
			date,
			formatDate,
		};
	},
	data() {
		return {
			pdfFactory: undefined,
			pdfData: undefined,
			pdfPages: 0,
			pdfurl: '',
			loadTask: undefined,
			templateNames: [],
			templates: [],
			showRandomData: true,
			templateModalOpen: false,
			templateNameModalOpen: false,
			ocrHelpModalOpen: false,
			ocrCurrentProcesses: 0,
			ocrCombinedCap: 0,
			ocrStatusInterval: undefined,
			isOcrFree: true,
			ocrFileCheck: undefined,
			index: 0,
			counter: 0,
			randomData: [
				{
					key: "key1",
					key2: "key2",
					key3: "key3",
					value: "",
					category: "AnyInput",
					hidden_value: "",
					activeSelect: false,
					selectedOption: {
						text: this.$t("formview.AnyInput"),
						value: "AnyInput",
						type: "text",
					},
				},
			],
			plusSvg: require("../assets/plus.svg"),
			minusSvg: require("../assets/minus.svg"),
			formData: new FormData(),
			workingStatus: this.$t("StoreCommits.workingStatus"),
			message: this.$t("LoadingMessages.pdfLoading"),

			options: [
				{ value: "Any Data", text: this.$t("formview.AnyInput"), type: "text" },
				{ value: "Date", text: this.$t("formview.Date"), type: "text" },
				{ value: "Name", text: this.$t("formview.Name"), type: "text" },
				{ value: "Number", text: this.$t("formview.Number"), type: "text" },
				{ value: "Birth number", text: this.$t("formview.Birth number"), type: "text" },
				{ value: "E-mail", text: this.$t("formview.E-mail"), type: "text" },
				{ value: "Phone number", text: this.$t("formview.Phone number"), type: "text" },
				{ value: "Postal code", text: this.$t("formview.Postal code"), type: "text" },
				{ value: "ID number", text: this.$t("formview.ID number"), type: "text" },
				{ value: "Passport number", text: this.$t("formview.Passport number"), type: "text" },
				{ value: "IBAN number", text: this.$t("formview.IBAN number"), type: "text" },
				// { value: "Datum-2", text: "Dátum-2", type: "date-custom" },
			],
			ocrOptions: [
				{ value: 'OCR', text: 'Ocr' },
				{ value: 'noOcr', text: this.$t("formview.WithoutOcr") },
				{ value: 'Auto', text: this.$t("formview.Automatic") }
			],
			ocrprocess: 'Auto',

		};
	},

	computed: {
		shouldDisableButton() {
			return this.$store.state.arrayOfDocuments.length > 1 || (!this.isOcrFree && (this.ocrprocess == 'OCR' || (this.ocrprocess == 'Auto' && this.ocrFileCheck == true)));
		},

		disableButton() {
			console.log(!this.isOcrFree && (this.ocrprocess == 'OCR' || (this.ocrprocess == 'Auto' && this.ocrFileCheck == true)))
			return !this.isOcrFree && (this.ocrprocess == 'OCR' || (this.ocrprocess == 'Auto' && this.ocrFileCheck == true));
		},

		isSaveTemplateDisabled() {
			return (

				!this.randomData.some(item => item.value !== "")
			);
		},
	},

	methods: {
		getOcrStatus() {
			this.axios.get(EnvProvider.value('API_URL') + this.$root.API_OCR_STAUS)
				.then(response => {
					if (response.status === 200) {
						let files = response.data
						this.ocrCurrentProcesses = files.active_processes
						this.ocrCombinedCap = files.combined_ocr_cap

						if (this.ocrCurrentProcesses >= this.ocrCombinedCap) {
							this.isOcrFree = false
							console.log('OCR IS FULL')
						}
						else {
							this.isOcrFree = true
						}
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		grabValues(templateName) {
			console.log("grabbing values");
			let template = {
				name: templateName,
				values: {}
			};

			for (var index in this.randomData) {
				const input = this.randomData[index];
				const inputName = index;

				if (input.value !== '') {
					console.log(input);
					template.values[inputName] = {
						"category": input.selectedOption.value,
						"type": input.selectedOption.value,
						"input_value": input.value
					}
				}
			}
			this.templates.push(template);


			this.axios.post(EnvProvider.value('API_URL') + this.$root.API_TEMPLATE, template)
				.then(response => {
					if (response.status === 200) {
						console.log("Templates sent");
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		handleClick(e) {
			if (e.target.classList.contains("modalTemplates")) {
				this.templateModalOpen = false; 
			}
			// if (e.target.classList.contains("modalNameTemplates")) {
			// 	this.templateNameModalOpen = false;
			// }
		},
		showTemplateModal() {
			this.getTemplates();
			this.templateModalOpen = true;
		},
		closeTemplateModal() {
			this.templateModalOpen = false;
		},
		showTemplateNameModal() {
			this.getTemplates();
			this.templateNameModalOpen = true;
		},
		closeTemplateNameModal() {
			this.templateNameModalOpen = false;
		},
		showOcrHelpModal() {
			this.ocrHelpModalOpen = true
		},
		closeOcrHelpModal() {
			this.ocrHelpModalOpen = false
		},
		applyTemplate(template) {
			this.randomData = [];

			for (const key in template.values) {
				const templateValue = template.values[key];
				const existingInput = this.randomData.find(input => input.selectedOption.value === templateValue.input_value);

				if (existingInput) {
					existingInput.value = templateValue.input_value;
				} else {
					this.randomData.push({
						key: this.generateRandomString(16),
						key2: this.generateRandomString(16),
						key3: this.generateRandomString(16),
						value: templateValue.input_value,
						category: templateValue.category,
						hidden_value: "",
						activeSelect: false,
						selectedOption: {
							text: this.$t("formview." + templateValue.category),
							value: templateValue.type,
							type: "text",
						},
					});
				}
			}

			this.$nextTick(() => {
				this.validateAllInputs();
			});
		},
		async applyManualTemplate(template) {
			const files = this.$store.state.arrayOfDocuments;
			var axiosPromises = []
			let fileIds = await this.sendFilesToAnonyma(true, true);
			console.log(fileIds);
			for (let index = 0; index < files.length; index++) {
				let resp = this.manual(files[index], fileIds[index], template);
				axiosPromises.push(resp);
			}

			await Promise.all(axiosPromises);

			this.$store.commit('pushMsg', { type: 'ok', text: 'Dokumenty boly úspešne zanonymizované' });
			this.$router.push({
				name: 'DownloadView'
			});

		},
		async manual(file, fileid, template) {
			const reader = new FileReader();
			var objects_to_annotate = Object.values(template.values);

			await new Promise((resolve) => {
				reader.onload = () => {
					resolve();
				};
				reader.readAsArrayBuffer(file);
			});

			const pdfData = new Uint8Array(reader.result);
			const loadTask = getDocument({ data: pdfData });

			const pdfDocument = await loadTask.promise;
			const data = await pdfDocument.getData();
			const pdfFactory = new AnnotationFactory(data);

			for (let index = 0; index < objects_to_annotate.length; index++) {
				const element = objects_to_annotate[index];
				if (element.type == '/Highlight') {
					pdfFactory.createHighlightAnnotation({
						page: element.page,
						rect: element.rect,
						contents: element.contents,
						author: element.author,
						color: element.color
					});
				} else if (element.type == '/Square') {
					pdfFactory.createSquareAnnotation({
						page: element.page,
						rect: element.rect,
						contents: element.contents,
						author: element.author,
						color: element.color
					})
				}
			}

			const annotatedData = pdfFactory.write();

			let formData = new FormData()
			let dataBlob = new Blob([annotatedData], { type: 'application/pdf' })
			formData.append('file', dataBlob)
			formData.append('fileName', fileid)

			const resp = await this.axios.post(
				EnvProvider.value('API_URL') + this.$root.API_ANON,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						'x-csrf-token': this.cookies.get('csrfToken')
					}
				}
			);

			return resp
		},
		validateAllInputs() {
			// Implement the logic to validate all inputs
			for (const input of this.randomData) {
				this.validate(input.value, input.selectedOption.value, input.key, input.key2);
			}
		},
		sendTemplates() {
			this.axios.post(EnvProvider.value('API_URL') + this.$root.API_TEMPLATE, this.templates)
				.then(response => {
					if (response.status === 200) {
						console.log("Templates sent");
					}
				}).catch(error => {
					console.log(error);
				});
		},
		removeTemplates() {
			this.axios.post(EnvProvider.value('API_URL') + this.$root.API_RM_TEMPLATE + this.$root.API_TEMPLATE, this.templates)
				.then(response => {
					if (response.status === 200) {
						console.log("Templates sent");
					}
				}).catch(error => {
					console.log(error);
				});
		},
		removeTemplate(template) {
			const indexToRemove = this.templates.indexOf(template);
			if (indexToRemove !== -1) {
				this.templates.splice(indexToRemove, 1);
				this.removeTemplates()
			}
		},
		getTemplates() {
			this.axios.get(EnvProvider.value("API_URL") + this.$root.API_TEMPLATE, this.templates)
				.then(response => {
					if (response.status === 200) {
						console.log("Templates received");
						let data = JSON.parse(response.data);
						if (Object.keys(data).length === 0) {
							this.templates = []
						}
						else {
							this.templates = data.templates
						}
						console.log('******');
						console.log(response.data)
						this.templateNames = this.templates.map(template => template.name);
					}
				}).catch(error => {
					console.log(error);
				});
		},
		datePickerShow(value) {
			if (value === "date-custom") {
				return true;
			}
			return false;
		},
		datePickerHide(value) {
			if (value === "date-custom") {
				return false;
			}
			return true;
		},
		activateSelect(key) {
			// console.log(this.randomData)
			// console.log("activateSelect key ", key);
			let field = this.randomData.find((x) => x.key === key);
			window.addEventListener('keydown', function (event) {
				if (event.key === 'Escape') {
					field.activeSelect = false;
					event.stopPropagation()
				}
			})
			if (field.activeSelect) {
				// console.log("idem ho zatvorit");
				field.activeSelect = false;
			} else {
				// console.log("otvaram");
				field.activeSelect = true;
			}
			// console.log("activateSelect field", field);
		},
		selectOption(text, option, value, key, key2, type) {
			console.log("SELECT", text, option, value, type);
			// najdem objekt v randomData podla kluca a nastavim jeho selectedOption
			let field = this.randomData.find((x) => x.key === key);
			field.category = text
			field.selectedOption.value = option;
			field.selectedOption.text = text;
			field.selectedOption.type = type;
			// console.log("option value key key2: ", option, value, key, key2);
			this.validate(value, option, key, key2);
		},
		generateRandomString(length) {
			const characters =
				"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			let result = "";

			for (let i = 0; i < length; i++) {
				const randomIndex = Math.floor(Math.random() * characters.length);
				result += characters.charAt(randomIndex);
			}

			return result;
		},
		validate(value, category, key, key2) {
			if (category === undefined) {
				category = "AnyInput";
			}
			if (value !== "") {
				// console.log(Date.parse(value));
				console.log('xxx', value, category);
				if (validator(value, category)) {
					// console.log("valid");
					document.getElementById(key2).style.border = "1px solid green";
					document.getElementById(key2).style.boxShadow = "0 0 0 0.2rem rgba(0, 255, 0, 0.25)";
					this.randomData.find((x) => x.key === key).hidden_value =
						Date.parse(value);
					// console.log(this.randomData);
				} else {
					// console.log("invalid");
					document.getElementById(key2).style.border = "1px solid red";
					document.getElementById(key2).style.boxShadow =
						"0 0 0 0.2rem rgba(255, 0, 0, 0.25)";
				}
			} else {
				document.getElementById(key2).style.border = "1px solid #ced4da";
				document.getElementById(key2).style.boxShadow = "none";
			}
		},
		addFormField() {
			this.randomData.push({
				key: this.generateRandomString(16),
				key2: this.generateRandomString(16),
				key3: this.generateRandomString(16),
				value: "",
				category: "AnyInput",
				hidden_value: "",
				activeSelect: false,
				selectedOption: { text: this.$t("formview.AnyInput"), value: "AnyInput", type: "text" },
			});
		},
		removeFormField(index) {
			this.randomData.splice(index, 1);
		},
		checkFileOcr() {
			console.log('Processing file and checking if OCR is needed')

			const files = this.$store.state.arrayOfDocuments;
			let formData = new FormData();
			const allAttributes = this.prepareDataIntoAnnotationNewFormView();
			for (let index = 0; index < files.length; index++) {
				console.log('inside for loop: ' + index)
				formData.append('file', files[index]);
				formData.append("formJSON", JSON.stringify(allAttributes));
				formData.append("ocr", this.ocrprocess)
				if (files.length > 1) {
					var zip_id = uuidv4();
					formData.append('zip_id', zip_id);
				}
			}

			this.axios.post(EnvProvider.value("API_URL") + this.$root.API_OCR_FILE_CHECK, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					uToken: this.cookies.get("userToken"),
				},
			})
				.then((response) => {
					if (response.status === 200) {
						console.log(response.data['ocrCheck'])
						this.ocrFileCheck = response.data['ocrCheck']
					}
				})
		},
		async sendFilesToAnonyma(manualAnnotation, fromTemplate) {
			if (manualAnnotation === true) {
				this.message = this.$t("LoadingMessages.pdfLoading");
			} else if (manualAnnotation === false) {
				this.message = this.$t("LoadingMessages.downloadLoading");
			}
			this.$store.state.showOverlay = true;

			const allAttributes = this.prepareDataIntoAnnotationNewFormView();
			var newAllAttributes = []

			for (let i = 0; i < allAttributes.length; i++) {
				if (allAttributes[i].content[0].value !== '') {
					newAllAttributes.push(allAttributes[i]);
				}
			}
			this.$store.commit("pushMsg", {
				type: "ok",
				text: this.$t("StoreCommits.sentForProcessing"),
			});

			const files = this.$store.state.arrayOfDocuments;

			if (files.length > 1) {
				var zip_id = uuidv4();
				if (manualAnnotation && !fromTemplate) {
					console.error("Illegal situation. Cannot do manual annotation with a template.")
					this.$store.commit("pushMsg", {
						type: "error",
						text: this.$t("StoreCommits.processingError"),
					});
					return
				}
			}

			let dataResponse = null
			var axiosCalls = []
			let fileIds = {}
			for (let index = 0; index < files.length; index++) {
				console.log('inside for loop: ' + index)
				const formData = new FormData();
				formData.append('file', files[index]);
				formData.append("formJSON", JSON.stringify(allAttributes));
				formData.append("manual", manualAnnotation);
				formData.append("ocr", this.ocrprocess)
				if (files.length > 1) {
					formData.append('zip_id', zip_id);
				}

				const axiosPromise = this.axios.post(
					EnvProvider.value("API_URL") + this.$root.API_POST,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
							uToken: this.cookies.get("userToken"),
						},
					}
				).then((response) => {
					console.log('response:')
					console.log(response.data)
					fileIds[index] = response.data.fileid

					if (response.data.ocrstatus === 'full') {
						console.log('CAPACITY FULL')
						console.log(response.data.activeocrdocs)
						console.log(response.data.allOcrCap)
					} else if (manualAnnotation === true) {
						dataResponse = response.data
					} else {
						this.$store.commit("pushMsg", {
							type: "ok",
							text: this.$t("StoreCommits.successfulAnnot"),
						});
					}
				}).catch((error) => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log(error.response.data);
						this.$store.commit("pushMsg", {
							type: "error",
							text: this.$t("StoreCommits.processingError"),
						});
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
						this.$store.commit("pushMsg", {
							type: "error",
							text: this.$t("StoreCommits.processingError"),
						});
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.request);
						this.$store.commit("pushMsg", {
							type: "error",
							text: this.$t("StoreCommits.processingError"),
						});
					}
					this.$store.state.showOverlay = false
				}
				);
				axiosCalls.push(axiosPromise)
			};

			await Promise.all(axiosCalls);

			this.$store.state.showOverlay = false;

			if (files.length > 1) {
				if (manualAnnotation === true) {
					if (fromTemplate === true) {
						// 1+ file, manual YES, template YES
						// return fileIds
						return fileIds
					} else {
						// 1+ file, manual YES, template NO
						// illegal
						console.error("This should not happen");
						return
					}
				} else {
					if (fromTemplate === true) {
						// 1+ file, manual NO, template YES
						// go to download view
						this.$store.commit('pushMsg', { type: 'ok', text: 'Dokumenty boly úspešne anonymizované' });
						this.$router.push({
							name: 'DownloadView'
						});
					} else {
						// 1+ file, manual NO, template NO
						// go to download view
						this.$store.commit('pushMsg', { type: 'ok', text: 'Dokumenty boly úspešne anonymizované' });
						this.$router.push({
							name: 'DownloadView'
						});
					}
				}
			} else {
				if (manualAnnotation === true) {
					if (fromTemplate === true) {
						// 1 file, manual YES, template YES
						// return fileIds
						return fileIds
					} else {
						// 1 file, manual YES, template NO
						// go to manual annotation view
						let minexData = JSON.stringify(dataResponse.minex);
						this.$router.push({
							name: "ManualAnnotationView",
							params: {
								documentUrl: dataResponse.url,
								fileId: dataResponse.fileid,
								minex: minexData,
							},
						});
					}
				} else {
					if (fromTemplate === true) {
						// 1 file, manual NO, template YES
						// go to download view
						this.$store.commit('pushMsg', { type: 'ok', text: 'Dokument bol úspešne anonymizovaný' });
						this.$router.push({
							name: 'DownloadView'
						});
					} else {
						// 1 file, manual NO, template NO
						// go to download view
						this.$store.commit('pushMsg', { type: 'ok', text: 'Dokument bol úspešne anonymizovaný' });
						this.$router.push({
							name: 'DownloadView'
						});
					}
				}
			}
		},
		prepareDataIntoAnnotation() {
			var allAttributes = [];
			var anonymizedFields = this.$root.anonymizedFields;
			for (var section in anonymizedFields) {
				var sectionData = {
					categoryName: this.$t(anonymizedFields[section].label),
					content: [],
				};
				const params = anonymizedFields[section].parameters;
				for (var field in params) {
					if (params[field].input !== "") {
						var singleParameter = {
							inputName: this.$t(params[field].label),
							value: params[field].input,
						};
						sectionData.content.push(singleParameter);
					}
				}
				if (sectionData.content.length !== 0) {
					allAttributes.push(sectionData);
				}
			}
			if (this.randomData || this.randomData !== "") {
				sectionData = {
					categoryName: this.$t("StoreCommits.anyParam"),
					content: [],
				};
				singleParameter = {
					inputName: "randomData",
					value: this.randomData,
				};
				sectionData.content.push(singleParameter);
				allAttributes.push(sectionData);
			}
			return allAttributes;
		},
		prepareDataIntoAnnotationNewFormView() {
			var allAttributes = [];
			if ((this.randomData.length > 1) || (this.randomData[0].value !== "")) {
				for (var form in this.randomData) {
					var sectionData = {
						categoryName: "non-categoric",
						content: [],
					};
					var singleParameter = {
						inputName: this.randomData[form].category,
						value: this.randomData[form].value,
					};
					sectionData.content.push(singleParameter);
					allAttributes.push(sectionData);
				}
			}
			return allAttributes;
		},
	},
	created() {
		this.$store.state.showOverlay = false;
		setTimeout(() => {
			var documentLink = "";
			if (this.$route.query.documentLink) {
				this.$store.state.showOverlay = true;
				documentLink = decodeURIComponent(this.$route.query.documentLink);
				this.axios
					.get(
						EnvProvider.value("API_URL") +
						this.$root.API_CRZ +
						"?documentLink=" +
						documentLink,
						{
							headers: {
								"Content-Type": "application/octet-stream",
							},
							responseType: "blob",
						}
					)
					.then((response) => {
						this.$store.state.showOverlay = false;
						if (response.status === 200) {
							var filename = documentLink.split("/").pop();
							var octetStreamMime = "application/octet-stream";
							var contentType =
								response.headers["content-type"] || octetStreamMime;
							var file = new File([response.data], filename, {
								type: contentType,
							});
							this.$store.commit("setDocuments", [file]);
							console.log(this.$store.state.arrayOfDocuments)
						} else {
							this.$store.commit("pushMsg", {
								type: "error",
								text: this.$t("StoreCommits.downloadError"),
							});
						}
					});
			}
		}, 200);
		setTimeout(() => {{
			this.checkFileOcr()
		}}, 1000) // BULHARSKA KONSTANTA
		this.getOcrStatus()
		this.ocrStatusInterval = setInterval(this.getOcrStatus, 5000)
	},
	beforeUnmount() {
		window.clearInterval(this.ocrStatusInterval)
	},
};
</script>

<style lang="scss" src="@/assets/css/views/FormView.scss" />
<style lang="scss" src="@/assets/css/SecondaryButton.scss" />
<style lang="scss" src="@/assets/css/ConfirmButton.scss" />
<style lang="scss" src="@/assets/css/saveTemplateButton.scss" />
<style lang="scss" src="@/assets/css/myTemplatesButton.scss" />
<style lang="scss" src="@/assets/css/ocr.scss" />
<!-- <style lang="scss" src="@/assets/css/ocrFull.scss" /> -->
