<template>
    <LoadingOverlay :message="message" />
    <div id="selectorDiv" v-on:mouseup="onmouseup" v-on:mousemove="onmousemove"
        style="border: 2px solid #000; position: absolute; z-index:5; visibility: hidden;"></div>
    <div class="zoom" v-if="!widthCheck">
        <img src="../../assets/button_home.svg" alt="" @click="prelinkToHome()" height="50">
        <img src="../../assets/button_obnovenie.svg" alt="" v-on:click="reRenderPdf(true)" height="50"
            :title='$t("pdfviewer.docRefresh")'>
        <img src="../../assets/button_anotovanie.svg" alt="" v-on:click="addHighlightAnnotation" height="50"
            :title='$t("pdfviewer.textAnotation")'>
        <img src="../../assets/button_volna_an.svg" alt="" v-on:click="addSquareAnnotation" height="50"
            :title='$t("pdfviewer.freeAnotation")' :class="{ activeButton: doSquare }">
        <button @click="showTemplateNameModal" type="button" class="downloadDocButtonAlt" id="saveTemplate">{{ $t("formview.SaveTemplate") }}</button>

        <teleport to="body">
            <div class="modalNameTemplates" v-show="templateNameModalOpen" @click="handleClick">
                <div class="modalViewNameTemplates">
                    <templateNameModal v-show="templateNameModalOpen" @close="closeTemplateNameModal"
                        :templateNames="templateNames" :content="randomData" @save-template="saveTemplate" />
                </div>
            </div>
        </teleport>
        
        <button class="downloadDocButtonAlt" v-on:click="download" id="download" :title='$t("pdfviewer.downloadDoc")'>{{
            $t("pdfviewer.downloadDoc") }}</button>
        <button class="sendDocButtonAlt" v-on:click="pushPdf" id="pushPdf" :title='$t("pdfviewer.sendForAnonymization")'>{{
            $t("pdfviewer.sendForAnonymization")
        }}</button>
    </div>
    <div class="viewContainer">
        <div v-if="!toggleAnnotMenu" class="toggleAnnotDiv">
            <img src="../../assets/double-chevron-right.svg" class="toggleAnnotMenu"
                @click="toggleAnnotMenu = !toggleAnnotMenu">
        </div>
        <div v-else-if="toggleAnnotMenu" class="toggleAnnotDiv">
            <transition name="slideFade">
                <div class="leftSideBar" v-if="toggleAnnotMenu">
                    <div class="sideBarContainer leftContainer">
                        <div id="leftSideBarContainer" style="height: 100%;">
                            <div class="selectContainer" v-if="widthCheck">
                                <img src="../../assets/button_home.svg" alt="" @click="prelinkToHome()">
                                <img src="../../assets/button_obnovenie.svg" alt="" v-on:click="reRenderPdf(true)"
                                    :title='$t("pdfviewer.docRefresh")'>
                                <img src="../../assets/button_anotovanie.svg" alt="" v-on:click="addHighlightAnnotation"
                                    :title='$t("pdfviewer.textAnotation")'>
                                <img src="../../assets/button_volna_an.svg" alt="" v-on:click="addSquareAnnotation"
                                    :title='$t("pdfviewer.freeAnotation")' :class="{ activeButton: doSquare }">
                            </div>

                            <div class="itemContainer">
                                <h3> {{ $t("pdfviewer.anotInText") }} </h3>
                                <div class="itemContainerImages">
                                    <img src="../../assets/button_delete_highlights.svg" alt="" v-on:click="deleteAllHighlightedAnnots"
                                    :title='$t("pdfviewer.delAllHighlights")'>
                                    <img src="../../assets/button_delete_anots.svg" alt="" v-on:click="deleteAnnotations"
                                        :title='$t("pdfviewer.delAllAnotations")'>
                                </div>
                            </div>

                            <div id="annotationContainer">
                                <!-- <b>{{ $t("pdfviewer.textanotation") }}</b> -->
                                <div id="annotations" v-for="(page, index) in uniqueAnnotations" :key="index">
                                    <div v-for="(annot, index2) in page" :key="index2">
                                        <div v-on:click="highlightAnnotation(annot.contents)" :ref="`annot_page${index}_index${index2}`" :class="[customColorClass(annot.author),'annotation']" v-if="checkAnnotType(annot.type) && this.controlDeletedObjects(annot.id, annot.page)">
                                                <div class="textAnnotationSection">
                                                    {{ annotationTypeFormatter(annot.type) }}
                                                    <label class="annotationPage"> {{ annotationPageFormatter(annot.page) }} </label>
                                                </div>
                                            <div class="deleteAnnotationSection">
                                                <label @click="deleteAnnotation(annot.contents)" class="deleteAnnotation">
                                                    <img src="../../assets/NOT_icon.svg" class="notIcon">
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="itemContainerButton" v-if="widthCheck">
                                <button class="downloadDocButton" v-on:click="download" id="download"
                                    :title='$t("pdfviewer.downloadDoc")'>{{ $t("pdfviewer.downloadDoc") }}</button>
                            </div>
                            <div class="itemContainerButton" v-if="widthCheck">
                                <button class="sendDocButton" v-on:click="pushPdf" id="pushPdf"
                                    :title='$t("pdfviewer.sendForAnonymization")'>{{ $t("pdfviewer.sendForAnonymization")
                                    }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <img src="../../assets/double-chevron-left.svg" class="toggleAnnotMenu"
                @click="toggleAnnotMenu = !toggleAnnotMenu" />
        </div>

        <div id="view">
            <div id="viewerContainer" :style="{ height: heightOfViewer + 'px', marginLeft: '25px', marginRight: '25px' }" v-on:mousedown="onmousedown"
                v-on:mouseup="onmouseup" v-on:mousemove="onmousemove" style="padding-top: 30px;padding-bottom: 30px;">
                <div id="viewer" class="pdfViewer"></div>
            </div>
        </div>

        <div v-if="!toggleSuggestMenu" class="toggleSuggestDiv">
            <img src="../../assets/double-chevron-left.svg" class="toggleSuggesterMenu"
                @click="toggleSuggestMenu = !toggleSuggestMenu" />
        </div>
        <div v-else-if="toggleSuggestMenu" class="toggleSuggestDiv">
            <img src="../../assets/double-chevron-right.svg" class="toggleSuggesterMenu"
                @click="toggleSuggestMenu = !toggleSuggestMenu" />
            <transition name="slideFade">
                <div class="rightSideBar" v-if="toggleSuggestMenu">
                    <div class="sideBarContainer">
                        <div class="itemContainer">
                            <h3>{{ $t("pdfviewer.textrecomendation") }}</h3>
                        </div>

                        <div id="suggestedAnnots">
                            <div class="selectAll">
                                <span class="annotation">
                                    <input class="inputCheckAll" type="checkbox" v-model="checkAll" id="idCheckAll"
                                        @click="suggestedCheckboxState">
                                    <label class="labelCheckAll"> {{ checkAll == true ? $t("pdfviewer.cancelAnnotation") :
                                        $t("pdfviewer.selectAll") }} </label>
                                </span>
                            </div>
                            <span class="annotation" v-for="(item, key) in suggestedAnnotations" :key="key">
                                <input class="suggestedInputField" :checked="isCheckedAnnot(key)" type="checkbox"
                                    @change="markSuggested(item.coordinates, item.pageNumber, key)"
                                    :name="item.category + ' - ' + item.phrase" :value="item.phrase" :id="key" />
                                <div class="textAnnotationSection textRecommendSection" v-on:click="highlightSuggested(item.coordinates, item.pageNumber, key)">
                                    <label> {{ setLabelForRecommendCategory(item.category) }} </label>
                                    <label> <b> {{ item.phrase }} </b> </label>
                                    <label class="annotationPage"> {{ $t("pdfviewer.page") + " " + String(item.pageNumber + 1)
                                    }}
                                    </label>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import '../../../node_modules/pdfjs-dist/web/pdf_viewer.css'
import '../../../node_modules/pdfjs-dist/build/pdf.js'
import { AnnotationFactory } from '../../../public/pdfAnnotate.js'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist'
import { PDFViewer } from '../../../node_modules/pdfjs-dist/web/pdf_viewer.js'
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';
import LoadingOverlay from "../LoadingOverlay.vue"
import templateNameModal from "../../components/templateNameModal.vue";

/** TODO: disable send/save button until promise from storing the new annotation is returned as successful
 *
 */
export default {


    name: "pdfviewer",
    components: { LoadingOverlay, templateNameModal },
    props: ["documentUrl", "fileId", "minex"],
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    data: function () {
        return {
            templateNameModalOpen: false,
            templateNames: [],
            template: [],
            color: "#BFC547",
            pdfurl: '',
            filename: '',
            annotations: undefined,
            pdfFactory: undefined,
            pdfViewer: undefined,
            loadTask: undefined,
            pdfData: undefined,
            pdfPages: 0,
            /*on the fly coordiinates*/
            once: false,
            pagesPosition: [],
            marginUnderPage: 10,
            lastCoordPage: 0,
            pageNumberCustomRect: 0,
            doSquare: false,
            coordinates: [],
            squarePages: [],
            htmlCoords: [],
            htmlcoordinates: [],
            initCoords: [],
            /*coordinates to render into html after refresh
            the coordinates should reflect the contents of the annotations of the documents without the flag "delete" set to True
            */
            renderedHtmlCoord: {},
            author: "",
            comment: "",
            textSelAnnots: [],
            annotCount: 0,
            pagesRendered: 0, //the number of rendered text for pages in the current render cycle
            suggestedAnnotations: {},
            checkedAnnots: {},
            annotGroups: [],
            markedAnnots: [],
            suggestAnnotIndexOffset: 10000,
            checkAll: false,
            toggleAnnotMenu: false,
            toggleSuggestMenu: false,
            heightOfViewer: '25px',
            widthCheck: (window.innerWidth > 2000),
            selectorDiv: undefined,
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0,
            message: 'Loading...',
            lastScale: undefined,
            rendering: false,
            isPushingPdf: false,
        }
    },

    created() {
        window.addEventListener('beforeunload', this.deleteBeforeUnload);
    },

    destroyed() {
        window.removeEventListener('beforeunload', this.deleteBeforeUnload);
    },

    mounted() {
        this.load();
        this.renderPdf();
        window.addEventListener('resize', this.handlePixelRatioChange);
    },

    beforeUnmount() {
        this.deleteBeforeUnload()
        window.removeEventListener('resize', this.handlePixelRatioChange);
    },

    computed: {
        uniqueAnnotations() {
            const uniqueContents = new Set();

            var annots = this.annotations.map((page) =>
                page.filter((annot) => {
                    if (typeof(annot.contents) === 'undefined') {
                        return false;
                    }
                    if (!uniqueContents.has(annot.contents)) {
                        uniqueContents.add(annot.contents);
                        return true;
                    }
                    return false;
                })
            );
            // console.log('checking for highlighting')
            // for (var page in annots) {
            //     for ( var annot in annots[page]){
            //         if (this.isAnnotHighlighted(annots[page][annot].contents)){
            //             annots[page][annot].highlighted = true;
            //         } else {
            //             annots[page][annot].highlighted = false;
            //         }
            //         console.log(annots[page][annot])
            //     }
            // }
            return annots;
        },
    },

    methods: {
        showTemplateNameModal() {
			this.getTemplates();
			this.templateNameModalOpen = true;
		},
		closeTemplateNameModal() {
			this.templateNameModalOpen = false;
		},

        getTemplates(){
            this.axios.get(EnvProvider.value("API_URL") + this.$root.API_TEMPLATE, this.templates)
                .then(response => {
                    if (response.status === 200) {
                        console.log("Templates received");
                        let data = JSON.parse(response.data);
						if (Object.keys(data).length === 0) {
							this.templates = []
						}
						else {
							this.templates = data.templates
						}
						this.templateNames = this.templates.map(template => template.name);
                        this.templates = [];
                        console.log(this.templateNames);
                    }
                }).catch(error => {
                    console.log(error);
                });
        },
        saveTemplate(templateName) {
            this.template = {name: templateName, values: {}};
            var count = 0
            this.pdfFactory.getAnnotations().then(
                annotations => {
                    annotations = this.annotations
                    var keys = Object.values(annotations);
                    for (var page = 0; page < keys.length; page++) {
                        for (let annot = 0; annot < keys[page].length; annot++) {
                            const element = keys[page][annot];
                            var mappedData = {
                                "rect": element.rect,
                                "color": element.color,
                                "type": element.type,
                                "opacity": element.opacity,
                                "type_encoded": element.type_encoded,
                                "author": element.author,
                                "contents": element.contents,
                                "page": element.page,
                                "quadPoints": element.quadPoints
                            };
                            this.template.values[count] = mappedData;
                            count++;
                        }
                    }


                    this.axios.post(EnvProvider.value('API_URL') + this.$root.API_TEMPLATE, this.template, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(response => {
                        if (response.status === 200) {
                            console.log("Templates sent");
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
            );
        },
        deleteBeforeUnload(event) {
            if (this.isPushingPdf) {
                return;
            }
            const fileId = this.fileId;
            this.axios.get(EnvProvider.value('API_URL') +'/delete/document/'+ fileId)
            .then(response => {
                console.log('ano');
            })
            .catch(error => {
                console.error('Failed to send file id', error);
            });
        },
        prelinkToHome() {
            this.changeRouting('HomeView')
        },

        showOverlay(message) {
            this.message = message;
            this.$store.state.showOverlay = true;
        },

        closeOverlay() {
            this.$store.state.showOverlay = false;
            this.message = this.$t("LoadingMessages.pdfLoading");
        },

        reCalc() { //This will restyle the div
            let x3 = Math.min(this.x1, this.x2); //Smaller X
            let x4 = Math.max(this.x1, this.x2); //Larger X
            let y3 = Math.min(this.y1, this.y2); //Smaller Y
            let y4 = Math.max(this.y1, this.y2); //Larger Y
            this.selectorDiv.style.left = x3 + 'px';
            this.selectorDiv.style.top = y3 + 'px';
            this.selectorDiv.style.width = (x4 - x3) + 'px';
            this.selectorDiv.style.height = (y4 - y3) + 'px';
        },

        onmousedown(e) {
            if (this.doSquare) {
                this.selectorDiv.style.visibility = "visible"; //Unhide the div
                let html = document.getElementsByTagName('html')[0]
                let top = html.scrollTop
                let left = html.scrollLeft
                let x = (e.clientX + left)
                let y = (e.clientY + top)
                // y = y % (this.pdfViewer._pages[0].canvas.height + 10)
                this.x1 = x
                this.y1 = y
                this.x2 = x
                this.y2 = y
                this.reCalc();
            }

            this.processCoordinates(e)

            // Highlight annot if clicked inside annot
            this.highlightClickedAnnot(e)



            console.log(this.annotations)
            console.log(this.annotGroups)
            console.log(this.checkedAnnots)
            // let annots = this.pdfFactory.getAnnotations()
            // annots.then(value => {
            //     console.log(value)
            // })

            //console.log(this.textSelAnnots)
            // console.log(this.annotations)

            //console.log(this.suggestedAnnotations)
        },

        onmousemove(e) {
            if (this.doSquare && this.htmlcoordinates.length == 2) {
                let html = document.getElementsByTagName('html')[0]
                let top = html.scrollTop
                let left = html.scrollLeft
                let x = (e.clientX + left)
                let y = (e.clientY + top)
                // y = y % (this.pdfViewer._pages[0].canvas.height + 10)
                this.x2 = x;
                this.y2 = y;
                this.reCalc();
            }
        },

        onmouseup(e) {
            //this.calculatePoints(e)

            if (this.doSquare && this.htmlcoordinates.length == 2) {
                this.selectorDiv.style.visibility = "hidden"; //Hide the div
                // save second point
                this.cleanSelectArea()
                this.processCoordinates(e)
            }
        },

        addMarkedAnnotation(rectangle, page, id){
            let annot = this.getMarkedAnnotation(id);

            if (annot) {
                annot.annots.push({
                    page: page,
                    rectangle: rectangle
                });
                this.markAnnot(rectangle, page,id);
            }
            else {
                let marked = {
                    id: id,
                    annots: []
                };
                marked.annots.push({
                    page: page,
                    rectangle: rectangle
                });
                this.markAnnot(rectangle, page,id);
                this.markedAnnots.push(marked);
            }
        },

        getMarkedAnnotation(id){
            for (var annot in this.markedAnnots){
                if (this.markedAnnots[annot].id == id) {
                    return this.markedAnnots[annot];
                }
            }
            return null;
        },

        removeMarkedAnnotation(id){
            this.markedAnnots = this.markedAnnots.filter(function(v) { return v.id != id })
            this.unmarkAnnot(id);
        },

        highlightedItemClass(author) {
            // Add highlighted class
            switch (author) {
                case 'anonymize-text':
                    return 'annot-manual-item-highlight';
                case 'anonymize-square':
                    return 'annot-manual-item-highlight';
                case 'anonymize-user-input':
                    return 'annot-user-item-highlight';
                case 'anonymize-minex':
                    return 'annot-minex-item-highlight';
                default:
                    return '';
            }
        },

        customColorClass(author) {
            // Check the author and return the corresponding color class
            switch (author) {
                case 'anonymize-text':
                    return 'annot-manual-bgcolor';
                case 'anonymize-square':
                    return 'annot-manual-bgcolor';
                case 'anonymize-user-input':
                    return 'annot-user-input-bgcolor';
                case 'anonymize-minex':
                    return 'annot-minex-bgcolor';
                default:
                    return '';
            }
        },

        filterRectangles(rectangles) {
            // setting for overlap filter
            // 0.3 means that if more than 30 % is NOT overlapped in x or y axis, we do not merge
            // or that we merge if 70% or more is overlapped in x or y axis
            let horizontalOverlap = 1.2
            let verticalOverlap = 0.7

            // Convert DOMRectList to a regular array
            var rectArray = Array.from(rectangles);

            // Filter empty width or height rectangles
            rectArray = rectArray.filter(rect => rect.width !== 0 && rect.height !== 0);

            // Sort rectangles by their top-left x-coordinate
            rectArray.sort((a, b) => {
                if (a.y !== b.y) {
                    return a.y - b.y;
                }
                return a.x - b.x;
            });

            // Initialize result array with the first rectangle
            const mergedRectangles = [];

            for (let i = 0; i < rectArray.length; i++) {
                let viewRect = this.calculateCoordinatesForHtmlFromRect(rectArray[i])
                this.calculateCoordinatesForPageFromPoint(viewRect[0],viewRect[1])
                let pageHeight = this.pagesPosition[this.lastCoordPage].height
                let pageWidth = this.pagesPosition[this.lastCoordPage].width
                let pageX = this.pagesPosition[this.lastCoordPage].x
                let pageY = this.pagesPosition[this.lastCoordPage].y
                // console.log('page: '+this.lastCoordPage+' y: '+pageY+' x: '+pageX+' height: '+pageHeight+' width: '+pageWidth)
                // console.log('rect: ',rectArray[i])


                if ((rectArray[i].height >= pageHeight) || (rectArray[i].width >= pageWidth)) {
                    //console.log('rect height or width is out of bounds')
                    continue;
                }

                if ((rectArray[i].y <= 0) || (rectArray[i].y >= pageHeight)) {
                    //console.log('rect y is out of bounds')
                    continue;
                }

                if ((rectArray[i].x <= pageX) || (rectArray[i].x >= pageX+pageWidth)) {
                    //console.log('rect x is out of bounds')
                    continue;
                }

                if (mergedRectangles.length == 0) {
                    mergedRectangles.push(rectArray[i]);
                    continue;
                }

                const current = rectArray[i];
                const previous = mergedRectangles[mergedRectangles.length - 1];
                //console.log(current, previous)
                // Check for overlap between current and previous rectangle
                if (current.x <= previous.x + (previous.width * horizontalOverlap)) {
                    //console.log('overlapping horizontally')
                    // Update previous rectangle's dimensions if necessary
                    if (current.y <= previous.y + (previous.height * verticalOverlap)) {
                        //console.log('overlapping vertically, updating previous rectangle');
                        previous.width = Math.max(
                            previous.x + previous.width,
                            current.x + current.width
                        ) - previous.x;
                        previous.height = Math.max(
                            previous.y + previous.height,
                            current.y + current.height
                        ) - previous.y;
                    } else {
                        // Add the current rectangle to the result array
                        mergedRectangles.push(current);
                    }
                } else {
                    // Add the current rectangle to the result array
                    mergedRectangles.push(current);
                }
            }
            //console.log(mergedRectangles);
            return mergedRectangles;
        },

        getSelectionBoxes() {
            // Get the current selection object
            const selection = window.getSelection();

            // Get the range object for the first selected range
            const range = selection.getRangeAt(0);

            // Get the bounding rectangle of the range
            const rect = range.getBoundingClientRect();

            // If the selection spans multiple lines
            if (range.getClientRects().length > 1) {
                // Get the client rectangles for each line
                let rects = range.getClientRects();
                // Filter rectangles
                rects = this.filterRectangles(rects)
                return rects
            } else {
                // The selection is on a single line, so we can use the original rectangle
                return [rect]
            }
        },

        cleanSelectArea() {
            this.selectorDiv.style.left = '0px';
            this.selectorDiv.style.top = '0px';
            this.selectorDiv.style.width = '0px';
            this.selectorDiv.style.height = '0px';
            this.x1 = 0
            this.x2 = 0
            this.y1 = 0
            this.y2 = 0
        },

        changeRouting(viewName) {
            console.log(viewName)
            this.$root.clearValues()
            this.$router.push({
                name: viewName,
            })
        },

        isCheckedAnnot(key) {
            return (typeof this.checkedAnnots[key] == 'object')
        },

        suggestedCheckboxState() {
            if (this.checkAll) {
                this.suggestedAnnotations.forEach((value, index, array) => {
                    if (this.checkedAnnots[index] != undefined) {
                        let id = this.checkedAnnots[index].id
                        delete this.checkedAnnots[index]
                        this.markedAnnots = this.markedAnnots.filter(function(v) { return v.id != id })
                        this.deleteAnnotation(id)
                    }
                })
            }
            else {
                this.selectAllSuggestedAnnot()
            }
        },

        parseHexToRGB() {
            let s = this.color.slice(1, 7)
            let aRgbHex = s.match(/.{1,2}/g)
            let aRgb = [
                parseInt(aRgbHex[0], 16),
                parseInt(aRgbHex[1], 16),
                parseInt(aRgbHex[2], 16)
            ]
            return aRgb;
        },

        getParameters() {

            let x = this.coordinates[0]
            let y = this.coordinates[1]

            return [x, y, 'test', this.author]
        },

        setPagesPosition() {
            this.pagesPosition = []
            const nPages = this.pdfViewer.pdfDocument._pdfInfo.numPages;

            for (let i = 1; i <= nPages; i++){
                let pg = document.getElementById("page" + i).parentElement;
                var rect = pg.getBoundingClientRect();
                let pgPos = {};

                if (i === 1){
                    pgPos = {
                        y: 0,
                        x: rect.x,
                        width: rect.width,
                        height: rect.height
                    }
                } else {
                    pgPos = {
                        y: this.pagesPosition[i-2].y + (this.pagesPosition[i-2].height + this.marginUnderPage),
                        x: rect.x,
                        width: rect.width,
                        height: rect.height
                    }
                }

                this.pagesPosition.push(pgPos);
            };
        },

        load() {
            // init values, start loading pdf
            this.pdfurl = this.documentUrl
            // Create a URL object
            const url = new URL(this.documentUrl, "http://example.com");
            // Get the value of the 'filename' parameter
            this.filename = url.searchParams.get("filename");
            console.log(this.filename)
            this.suggestedAnnotations = JSON.parse(this.minex)
            // this.pdfurl = './annpop.pdf'
            GlobalWorkerOptions.workerSrc = 'pdf.worker.js'
            this.pdfContainer = document.getElementById('viewerContainer')
            this.selectorDiv = document.getElementById('selectorDiv')

            // document.addEventListener('textlayerrendered', () => {
            //     console.log("textlayerrendered fired");
            //     console.log(this)
            // })

            this.pdfViewer = new PDFViewer({
                container: this.pdfContainer
            })

            this.pdfViewer.eventBus.on('textlayerrendered', () => {
                this.pagesRendered++;
            })
        },

        setXCSRFToken() {
            if (this.$store.state.loggedUser === 'testuser' && EnvProvider.value('ENV') === 'DEV') {
                return this.$root.TOKEN_CSRF
            } else {
                return this.cookies.get('csrfToken')
            }
        },

        async renderPdf(event = undefined) {
            // render the PDF
            this.showOverlay(this.$t("LoadingMessages.pdfLoading"));

            // Get zoom level before rendering
            this.lastScale = window.devicePixelRatio
            this.rendering = true;

            this.loadTask = getDocument({
                url: EnvProvider.value('API_URL') + this.pdfurl,
                withCredentials: (EnvProvider.value('ENV') === 'DEV'),
                httpHeaders: {
                    "x-csrf-token": this.setXCSRFToken()
                },
            })
            console.log("rendering...");
            this.loadTask.promise.then((pdfDocument) => {
                pdfDocument.getData().then((data) => {
                    this.pdfFactory = new AnnotationFactory(data)
                })
                this.pdfViewer.setDocument(pdfDocument)
                this.pdfPages = this.pdfViewer.pdfDocument._pdfInfo.numPages
                this.heightOfViewer = this.pdfPages * 1500

                console.log("rendering pages: ", this.pdfPages);
                let iter = 0
                let upperLimit = this.pdfPages * 20 // the upper limit of time loading can occur

                let interval = setInterval(() => {
                    this.showOverlay(this.$t("LoadingMessages.pdfLoading") + " " + this.pagesRendered + "/" + this.pdfPages);
                    if (this.pdfPages <= this.pagesRendered) {
                        // Rerender if zoom changed while rendering
                        if(this.lastScale != window.devicePixelRatio) this.reRenderPdf(false);

                        console.log("settings annotations in " + iter + "*150ms")
                        window.clearInterval(interval)
                        this.setAnnotations()

                        var pages = document.getElementsByClassName("page");
                        for (let i = 0; i < pages.length; i++) {
                            pages[i].style.border = "none";
                            pages[i].style.marginBottom = this.marginUnderPage + "px";
                        }
                        console.log("len: ", pages.length);

                        this.setPagesPosition()
                        this.closeOverlay();
                        this.rendering = false;
                        this.heightOfViewer = this.pagesPosition[this.pdfPages-1].y + this.pagesPosition[this.pdfPages-1].height
                    }
                    console.log("pagesRendered: ", this.pagesRendered);

                    if (++iter === upperLimit) {
                        window.clearInterval(interval)
                        let time = this.pdfPages * 20 * 150
                        this.$store.commit('pushMsg', { type: 'error', text: 'Dokument sa nepodarilo načítať. Doba načítania' + +time })
                        console.error("The number of cycles exceeded limit. Please try again or contact support. Allocated miliseconds: " + time)
                        alert("The number of cycles exceeded limit. Please try again or contact support. Allocated miliseconds: " + time)
                    }
                }, 150)

            })

        },

        reRenderPdf(reloadAnnotations) {
            // Get zoom level before rendering
            this.lastScale = window.devicePixelRatio
            this.rendering = true;

            this.showOverlay(this.$t("LoadingMessages.pdfLoading"));

            this.pagesRendered = 0
            this.pdfViewer = new PDFViewer({
                container: this.pdfContainer
            })

            this.loadTask.promise.then((pdfDocument) => {
                pdfDocument.getData().then((data) => {
                    if (reloadAnnotations) {
                        this.pdfFactory = new AnnotationFactory(data)
                    }
                })
                this.pdfViewer.setDocument(pdfDocument)

                let iter = 0
                let upperLimit = this.pdfPages * 20

                let interval = setInterval(() => {
                    this.showOverlay(this.$t("LoadingMessages.pdfLoading") + " " + this.pagesRendered + "/" + this.pdfPages);
                    if (this.pdfPages == this.pagesRendered) {
                        // Rerender if zoom changed while rendering
                        if(this.lastScale != window.devicePixelRatio) {
                            this.reRenderPdf(false);
                            return;
                        }

                        window.clearInterval(interval);
                        this.setAnnotations();
                        var pages = document.getElementsByClassName("page");
                        for (let i = 0; i < pages.length; i++) {
                            pages[i].style.border = "none";
                            pages[i].style.marginBottom = this.marginUnderPage + "px";
                        }

                        this.setPagesPosition()
                        this.textSelAnnots = []

                        for (let index in this.checkedAnnots) {
                            //redrawing one
                            let key = index //- this.suggestAnnotIndexOffset
                            if (typeof this.checkedAnnots[index].newRect == 'object') { //NEWRECT
                                let newRect = this.calculateCoordinatesForAnnot(
                                    [this.suggestedAnnotations[key].coordinates.x0,
                                    this.suggestedAnnotations[key].coordinates.y0,
                                    this.suggestedAnnotations[key].coordinates.x1,
                                    this.suggestedAnnotations[key].coordinates.y1], this.suggestedAnnotations[key].pageNumber)

                                //this.markAnnot(newRect, this.suggestedAnnotations[key].pageNumber);
                                this.addMarkedAnnotation(newRect,this.suggestedAnnotations[key].pageNumber,index)
                            }
                            if (typeof this.checkedAnnots[index].newRects == 'object') { //NEWRECTS << working with multiple rectangles
                                for (let one in this.checkedAnnots[index].newRects) {
                                    let newRect = this.calculateCoordinatesForAnnot(
                                        [this.suggestedAnnotations[key].coordinates[one].x0,
                                        this.suggestedAnnotations[key].coordinates[one].y0,
                                        this.suggestedAnnotations[key].coordinates[one].x1,
                                        this.suggestedAnnotations[key].coordinates[one].y1,], this.suggestedAnnotations[key].pageNumber)

                                    //this.markAnnot(newRect, this.suggestedAnnotations[key].pageNumber);
                                    this.addMarkedAnnotation(newRect,this.suggestedAnnotations[key].pageNumber,index)
                                }
                            }
                        }
                        this.closeOverlay();
                        this.rendering = false;
                    }
                    if (++iter === upperLimit) {
                        window.clearInterval(interval);
                        let time = this.pdfPages * 20 * 450
                        this.$store.commit('pushMsg', { type: 'error', text: 'Dokument sa nepodarilo načítať. Doba načítania' + +time })
                        console.error("The number of cycles exceeded limit. Please try again or contact support. Allocated miliseconds: " + time)
                        alert("The number of cycles exceeded limit. Please try again or contact support. Allocated miliseconds: " + time)
                    }
                }, 450)
            })
        },

        handlePixelRatioChange() {
            // Emit an event or perform any other actions when pixel ratio changes
            this.widthCheck = (window.innerWidth > 2000)
            if(this.rendering) return;
            this.reRenderPdf(false);
        },

        // highLightSuggested(coords, page, id) {
        //     /* TODO: consider also hig hightlights going through pages?
        //     */
        //     let newRect = []
        //     let newRects = []
        //     if (typeof (coords.x0) === 'undefined') {
        //         coords.forEach(element => {
        //             let pageNumber = 'page' + String(page + 1)
        //             let rect = this.calculateCoordinatesForAnnot([element.x0,
        //             element.y0,
        //             element.x1,
        //             element.y1], page)
        //             newRects.push(rect)
        //             this.drawAnnotation(
        //                 rect[0],
        //                 rect[2],
        //                 rect[1],
        //                 rect[3],
        //                 pageNumber
        //             )
        //         });

        //     } else {
        //         let pageNumber = 'page' + String(page + 1)
        //         newRect = this.calculateCoordinatesForAnnot(
        //             [coords.x0,
        //             coords.y0,
        //             coords.x1,
        //             coords.y1], page)
        //         this.drawAnnotation(
        //             newRect[0],
        //             newRect[2],
        //             newRect[1],
        //             newRect[3],
        //             pageNumber
        //         )
        //     }
        //     let idx = this.checkedAnnots[id]
        //     if (idx == undefined) {
        //         if (newRect.length != 0) {
        //             this.checkedAnnots[id] = { newRect, page, id }
        //         }
        //         else if (newRects.length != 0) {
        //             this.checkedAnnots[id] = { newRects, page, id }
        //         }
        //     } else {
        //         // remove from array.
        //         delete this.checkedAnnots[id]
        //         this.reRenderPdf(false)
        //         // redraw checked annots ^
        //     }


        //     if (Object.keys(this.checkedAnnots).length > 0) {
        //         this.checkAll = true
        //     } else if (Object.keys(this.checkedAnnots).length == 0) {
        //         this.checkAll = false
        //     }
        //     // Adding annotations TO pdf shloud be done when saving file
        // },

        markSuggested(coords, page, key) {
            let color = this.parseHexToRGB()
            const scale = this.pdfViewer._pages[page].viewport.scale
            const scale2 = 1//window.devicePixelRatio
            var maxY = this.pdfViewer._pages[page].viewport.height
            maxY = maxY / scale

            //id = id + this.suggestAnnotIndexOffset
            //let idx = this.getMarkedAnnotation(id);

            var newRect = []
            var newRects = []
            if (this.checkedAnnots[key] == undefined) {
                let id = this.getNewAnnotGroup(true)
                this.addAnnotGroup(id)
                console.log('gettin new annot id: ' + id)

                if (typeof (coords.x0) === 'undefined') {
                    coords.forEach(element => {
                        let rect = this.calculateCoordinatesForAnnot([element.x0,
                            element.y0,
                            element.x1,
                            element.y1], page)
                        newRects.push(rect)
                        this.addMarkedAnnotation(rect,page,id)
                        this.pdfFactory.createHighlightAnnotation(
                            page,
                            [(rect[0] / (scale2 * scale)),
                            (maxY - (rect[1] / (scale2 * scale))),
                            (rect[2] / (scale2 * scale)),
                            (maxY - (rect[3] / (scale2 * scale)))],
                            id.toString(),
                            "anonymize-minex",
                            { r : color[0], g : color[1], b : color[2] }
                        )
                    });
                } else {
                    var newRect = this.calculateCoordinatesForAnnot(
                        [coords.x0,
                        coords.y0,
                        coords.x1,
                        coords.y1], page)

                    this.addMarkedAnnotation(newRect, page, id)
                    this.pdfFactory.createHighlightAnnotation(
                        page,
                        [(newRect[0] / (scale2 * scale)),
                        (maxY - (newRect[1] / (scale2 * scale))),
                        (newRect[2] / (scale2 * scale)),
                        (maxY - (newRect[3] / (scale2 * scale)))],
                        id.toString(),
                        "anonymize-minex",
                        { r : color[0], g : color[1], b : color[2] }
                    )
                }
                if (newRect.length != 0) {
                    this.checkedAnnots[key] = { newRect, page, id}
                }
                else if (newRects.length != 0) {
                    this.checkedAnnots[key] = { newRects, page, id }
                }
                this.refreshListOfAnnotations()
            } else {
                console.log('test')
                let id = this.checkedAnnots[key].id
                delete this.checkedAnnots[key]
                this.markedAnnots = this.markedAnnots.filter(function(v) { return v.id !== id })

                this.deleteAnnotation(id)
            }


            if (Object.keys(this.checkedAnnots).length > 0) {
                this.checkAll = true
            } else if (Object.keys(this.checkedAnnots).length == 0) {
                this.checkAll = false
            }
        },

        selectAllSuggestedAnnot() {
            this.suggestedAnnotations.forEach((value, index, array) => {
                console.log(value, index)
                this.markSuggested(value.coordinates, value.pageNumber, index)
            })

        },

        calculateCoordinatesForAnnot(rect, actualPage) {
            const scale = this.pdfViewer._pages[actualPage].viewport.scale
            const scale2 = 1//window.devicePixelRatio
            let rec = rect
            let x_1 = (rec[0] * (scale2 * scale))
            let y_1 = (rec[1] * (scale2 * scale))
            let x_2 = (rec[2] * (scale2 * scale))
            let y_2 = (rec[3] * (scale2 * scale))
            return [x_1, y_1, x_2, y_2]
        },

        annotationTypeFormatter(annotType) {
            if (annotType == "/Highlight")
                return this.$t("pdfviewer.textAnotation")
            else if (annotType == "/FreeText" || annotType == "/Square")
                return this.$t("pdfviewer.freeAnotation")
            else return annotType
        },

        annotationPageFormatter(annotPage) {
            return this.$t("pdfviewer.page") + (annotPage + 1)
        },

        updateCoordinates() {
            let _str = this.coordinates.map((x) => Math.round(x)).join(",")
        },

        selectionCoordinates(x_1, y_1, x_2, y_2, actualPage) {
            let x_1_y_1 = this.pdfViewer._pages[actualPage].viewport.convertToPdfPoint(x_1, y_1)
            x_1 = x_1_y_1[0]
            y_1 = x_1_y_1[1]
            let x_2_y_2 = this.pdfViewer._pages[actualPage].viewport.convertToPdfPoint(x_2, y_2)
            x_2 = x_2_y_2[0]
            y_2 = x_2_y_2[1]
            return [x_1, y_1, x_2, y_2]
        },

        setAnnotations() {
            var annotations = this.pdfFactory.getAnnotations()
            annotations.then(value => {
                for (var i in value) {
                    for (var annotation in value[i]) {
                        if (this.checkAnnotType(value[i][annotation].type) && this.checkAnnotAuthor(value[i][annotation].author) && this.controlDeletedObjects(value[i][annotation].id, value[i][annotation].page)) {
                            let actualPage = value[i][annotation].page
                            let rect = value[i][annotation].rect
                            let groupid = value[i][annotation].contents

                            // let groupid = this.getNewAnnotGroup()
                            // this.addAnnotGroup(groupid)
                            // value[i][annotation].contents = groupid.toString()

                            if (value[i][annotation].author == 'anonymize-user-input') {
                                value[i][annotation].rect = [rect[0],rect[3],rect[2],rect[1]]
                            }

                            this.updateAnnotation(value[i][annotation])

                            // draw the annotation
                            const htmlCoords = this.calculateCoordinatesFromCustomRect(value[i][annotation].rect, actualPage)
                            this.markAnnot(htmlCoords, actualPage,groupid);
                        }
                    }
                }

                this.annotations = value
                this.loadAnnotGroups();
            })
        },

        updateAnnotation(updatedAnnotation) {
            let selectedColor = this.parseHexToRGB()
            const annotationObjectId = updatedAnnotation.object_id;
            console.log('updating annot:', annotationObjectId)
            // Delete the original annotation
            this.pdfFactory.deleteAnnotation(annotationObjectId)
                .then(value => {
                    console.log('deleting annot')
                    // Create a new annotation with updated values
                    if (updatedAnnotation.type === '/Highlight') {
                        console.log('creating new highlight anot')
                        console.log(updatedAnnotation.contents)
                        this.pdfFactory.createHighlightAnnotation({
                            page: updatedAnnotation.page,
                            rect: updatedAnnotation.rect,
                            contents: updatedAnnotation.contents,
                            author: updatedAnnotation.author,
                            color: {r : selectedColor[0], g : selectedColor[1], b : selectedColor[2]}
                            // Add other properties specific to highlight annotations
                        });
                    } else if (updatedAnnotation.type === '/Square') {
                        console.log('creating new square anot')
                        this.pdfFactory.createSquareAnnotation({
                            page: updatedAnnotation.page,
                            rect: updatedAnnotation.rect,
                            contents: updatedAnnotation.contents,
                            author: updatedAnnotation.author,
                            color: {r : selectedColor[0], g : selectedColor[1], b : selectedColor[2]}
                            // Add other properties specific to square annotations
                        });
                    }

                    var annotations = this.pdfFactory.getAnnotations()
                    annotations.then(value => {
                        console.log(value)
                        this.annotations = []
                        for (var i in value) {
                            this.annotations.push([])
                            for (var annotation in value[i]) {
                                if (this.checkAnnotType((value[i][annotation].type)) && this.checkAnnotAuthor(value[i][annotation].author) && this.controlDeletedObjects(value[i][annotation].id, value[i][annotation].page)) {
                                    this.annotations[i].push(value[i][annotation])
                                }
                            }
                        }
                        //this.annotations = value
                        this.loadAnnotGroups();
                    })
                })
                .catch(error => {
                    console.error('Error updating annotation:', error);
                });
        },

        controlDeletedObjects(id, page) {
            if (this.pdfFactory.toDelete) {
                for (var deleted of this.pdfFactory.toDelete) {
                    if (deleted.id === id && deleted.page == page) {
                        return false
                    }
                }
            }
            return true
        },

        checkAnnotAuthor(author) {
            if (author == "anonymize-text" || author == "anonymize-user-input" || author == "anonymize-square" || author == "anonymize-minex") {
                return true
            }
            else return false
        },

        checkAnnotType(annotType) {
            if (annotType == "/FreeText" || annotType == "/Highlight" || annotType == "/Square") {
                return true
            }
            else return false
        },

        refreshListOfAnnotations() {
            var annotations = this.pdfFactory.getAnnotations()
            annotations.then((value) => {
                this.annotations = []
                for (var page in value) {
                    let annots = []
                    for (var annotation in value[page]) {
                        if (this.checkAnnotType((value[page][annotation].type)) && this.checkAnnotAuthor(value[page][annotation].author) && this.controlDeletedObjects(value[page][annotation].id, value[page][annotation].page)) {
                            let groupid = parseInt(value[page][annotation].contents)
                            let rect = value[page][annotation].rect

                            if (value[page][annotation].author == 'anonymize-user-input') {
                                value[page][annotation].rect = [rect[0],rect[3],rect[2],rect[1]]
                                if (this.annotGroups.includes(groupid)){
                                    annots.push(value[page][annotation]);
                                } else {continue;}
                            } else{
                                annots.push(value[page][annotation]);
                            }

                        }
                    }
                    this.annotations.push(annots)
                }
                this.loadAnnotGroups()
            })
        },

        setLabelForRecommendCategory(categoryId) {
            const obj = {
                'birth_id': "resultview.BirthNumber",
                'date': "downloadview.date",
                'email_address': "contactInformation.email",
                'iban': "bankInformation.iban",
                'postal_code': "apartmentInformation.postalcode",
                'phone_number': "contactInformation.telephonenumber",
                'streets': "apartmentInformation.street",
                'cities': "apartmentInformation.town",
                'price': 'suggesterLabelObject.sum',
                'names': 'suggesterLabelObject.name',
                'ico': 'suggesterLabelObject.ico',
                'dic': 'suggesterLabelObject.dic',
                'icdph': 'suggesterLabelObject.icdph',
                'variabilny_symbol': 'suggesterLabelObject.variableSymbol',
                'faktura': 'suggesterLabelObject.invoiceNumber'
            }
            return this.$t(obj[categoryId])
        },

        createMarkDiv(groupid){
            let div = document.createElement("div");

            div.className = "groupid"+groupid;
            div.style.visibility = "hidden";
            var random_background = "rgba("+Math.floor(Math.random() * 256) + ","+Math.floor(Math.random() * 256)+","+Math.floor(Math.random() * 256)+","+(Math.random() * 1).toFixed(1)+")";
            div.style.background = "rgba(191,197,71,0.4)"
            div.style.position = "absolute";
            div.style.zIndex = "0";
            return div;
        },

        unmarkAnnot(groupid){
            let markedAnnots = document.getElementsByClassName('groupid'+ groupid);
            while (markedAnnots.length > 0) markedAnnots[0].remove();
        },

        markAnnot(rect, page, groupid){
            let markDiv = this.createMarkDiv(groupid);

            // Find top-left and bottom-right coordinates
            let x0 = Math.min(rect[0], rect[2]);
            let y0 = Math.min(rect[1], rect[3]);
            let x1 = Math.max(rect[0], rect[2]);
            let y1 = Math.max(rect[1], rect[3]);

            markDiv.style.left = x0 + 'px';
            markDiv.style.top = y0 + 'px';
            markDiv.style.width = (x1 - x0) + 'px';
            markDiv.style.height = (y1 - y0) + 'px';
            markDiv.style.visibility = 'visible';

            let pageElement = document.getElementById('page'+ (page+1)).parentElement
            pageElement.appendChild(markDiv);
        },

        createHighlighterDiv(groupid){
            //<div id="highlighterDiv" style="border: 2px solid #ff0000; position: absolute; z-index:5; visibility: hidden;"></div>
            let div = document.createElement("div");
            div.className = "highlighterDiv"+groupid;
            div.style.visibility = "hidden";
            div.style.border = "2px dashed #ff0000";
            div.style.margin = "-2px"
            div.style.position = "absolute";
            div.style.zIndex = "5";
            return div;
        },

        highlightAnnot(rect, page,groupid){
            // Find top-left and bottom-right coordinates
            let x0 = Math.min(rect[0], rect[2]);
            let y0 = Math.min(rect[1], rect[3]);
            let x1 = Math.max(rect[0], rect[2]);
            let y1 = Math.max(rect[1], rect[3]);

            //console.log(this.annotations)
            let highlighterDiv = this.createHighlighterDiv(groupid);
            let pageHeight = this.pdfViewer._pages[page].viewport.height
            let scale = this.pdfViewer._pages[page].viewport.scale
            x0 = x0 * scale
            y0 = pageHeight - (y0 * scale)
            x1 = x1 * scale
            y1 = pageHeight - (y1 * scale)

            highlighterDiv.style.left = x0 + 'px';
            highlighterDiv.style.top = y1 + 'px';
            highlighterDiv.style.width = (x1 - x0) + 'px';
            highlighterDiv.style.height = (y0 - y1) + 'px';
            highlighterDiv.style.visibility = 'visible';

            let pageElement = document.getElementById('page'+ (page+1)).parentElement
            pageElement.appendChild(highlighterDiv);
            highlighterDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },

        deleteAllHighlightedAnnots(){
            for (let i in this.annotGroups){
                this.deleteHighlightedAnnot(i);
            }
            for (let i in this.suggestedAnnotations){
                let groupid = parseInt(i) + this.suggestAnnotIndexOffset
                this.deleteHighlightedAnnot(groupid);
            }
            for (let page = 0; page < this.uniqueAnnotations.length; page++) {
                for (let annot = 0; annot < this.uniqueAnnotations[page].length; annot++) {
                    let annotation = this.uniqueAnnotations[page][annot]
                    this.deleteHighlightedAnnotListItem(annotation.author,page,annot)
                }
            }
        },

        deleteHighlightedAnnot(groupid){
            // Find all elements with the class name "highlighterDiv"
            var elements = document.getElementsByClassName("highlighterDiv"+groupid);

            // Convert the HTMLCollection to an array for easier manipulation
            var elementsArray = Array.from(elements);

            // Remove each element from the DOM
            elementsArray.forEach(function(element) {
                element.remove();
            });
        },

        deleteHighlightedAnnotListItem(author,page,index){
            // Remove highlight from annot list item
            let ref = 'annot_page' + page + '_index' + index
            let highlightClass = this.highlightedItemClass(author)
            this.$refs[ref][0].classList.remove(highlightClass);
        },

        isAnnotHighlighted(groupid){
            //console.log(groupid);
            // Find all elements with the class name "highlighterDiv"
            var elements = document.getElementsByClassName("highlighterDiv"+groupid);

            // Convert the HTMLCollection to an array for easier manipulation
            var elementsArray = Array.from(elements);
            //console.log(elementsArray)

            if (elementsArray.length > 0){
                return true;
            } else {
                return false;
            }
        },

        highlightAnnotation(groupid) {
            let annotPage = undefined
            let annotPageIndex = undefined
            let author = undefined

            let abort = false;
            for (let page = 0; page < this.uniqueAnnotations.length; page++) {
                for (let annot = 0; annot < this.uniqueAnnotations[page].length; annot++) {
                    if (this.uniqueAnnotations[page][annot].contents == groupid){
                        annotPage = page;
                        annotPageIndex = annot;
                        author = this.uniqueAnnotations[page][annot].author;
                        abort = true;
                        break;
                    }
                }
                if (abort) break;
            }

            //console.log(groupid, annotPage,annotPageIndex,author)

            if (this.isAnnotHighlighted(groupid)){
                this.deleteHighlightedAnnot(groupid);
                if (annotPage != undefined) {
                    this.deleteHighlightedAnnotListItem(author,annotPage,annotPageIndex);
                }
            } else {
                // Highlight pdf page annot
                for (let page=0; page < this.annotations.length; page++) {
                    for (let annot=0; annot < this.annotations[page].length; annot++) {

                        if (this.annotations[page][annot].contents == groupid) {
                            let annotation = this.annotations[page][annot]
                            let actualPage = annotation.page
                            this.highlightAnnot(annotation.rect, actualPage,groupid)
                        }
                    }
                }

                // Highlight annot list item
                if (annotPage != undefined) {
                    let ref = 'annot_page' + annotPage + '_index' + annotPageIndex
                    let highlightClass = this.highlightedItemClass(author)
                    this.$refs[ref][0].classList.add(highlightClass);
                    this.$refs[ref][0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        },

        highlightSuggested(coords, page, groupid) {
            groupid+=this.suggestAnnotIndexOffset
            if (this.isAnnotHighlighted(groupid)){
                this.deleteHighlightedAnnot(groupid);
            } else {
                let rect = []
                if (typeof (coords.x0) === 'undefined') {
                    coords.forEach(element => {
                        rect = this.calculateCoordinatesForAnnot(
                            [
                                element.x0,
                                element.y0,
                                element.x1,
                                element.y1
                            ], page)
                        }
                    );
                } else {
                    rect = this.calculateCoordinatesForAnnot(
                        [
                            coords.x0,
                            coords.y0,
                            coords.x1,
                            coords.y1
                        ], page)
                }

                let highlighterDiv = this.createHighlighterDiv(groupid);
                let x0 = rect[0]
                let y0 = rect[3]
                let x1 = rect[2]
                let y1 = rect[1]

                highlighterDiv.style.left = x0 + 'px';
                highlighterDiv.style.top = y1 + 'px';
                highlighterDiv.style.width = (x1 - x0) + 'px';
                highlighterDiv.style.height = (y0 - y1) + 'px';
                highlighterDiv.style.visibility = 'visible';

                let pageElement = document.getElementById('page'+ (page+1)).parentElement
                pageElement.appendChild(highlighterDiv);
                highlighterDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },

        highlightClickedAnnot(e) {
            // Calculate viewPoint coordinates
            let ost = this.computePageOffset();
            let x = (e.clientX - ost.left);
            let y = (e.clientY - ost.top);

            // Calculate pagePoint coordinates
            let pagePoint = this.calculateCoordinatesForPageFromPoint(x, y);

            // Get current page
            let currentPage = this.lastCoordPage

            let x_y = this.pdfViewer._pages[currentPage].viewport.convertToPdfPoint(pagePoint[0], pagePoint[1])
            x = x_y[0]
            y = x_y[1]

            for (var n in this.annotations[currentPage]){
                let annot = this.annotations[currentPage][n]
                let boundaryRect = annot.rect
                let groupid = annot.contents

                // Check if (x, y) is within the annotation rectangle
                if (
                    x >= boundaryRect[0] &&
                    x <= boundaryRect[2] &&
                    y <= boundaryRect[1] &&
                    y >= boundaryRect[3]
                ) {
                     this.highlightAnnotation(groupid);
                     break;
                }
            }
        },

        deleteAnnotation(groupid) {
            // uncheck if from suggested
            let keys = Object.keys(this.checkedAnnots)
            for (let index = 0; index < keys.length; index++) {
                let key = keys[index]
                if (this.checkedAnnots[key] == undefined) continue;
                console.log(this.checkedAnnots[key].id)
                if (this.checkedAnnots[key].id == groupid) {
                    console.log('deleting index: ',key)
                    delete this.checkedAnnots[key]
                    //this.removeMarkedAnnotation(groupid)

                    if (Object.keys(this.checkedAnnots).length > 0) {
                        this.checkAll = true
                    } else if (Object.keys(this.checkedAnnots).length == 0) {
                        this.checkAll = false
                    }
                }
            }

            if (this.isAnnotHighlighted(groupid)){
                this.deleteHighlightedAnnot(groupid);
            }

            this.unmarkAnnot(groupid);
            this.delAnnotGroup(groupid);

            // Create a deep copy of the annotations array
            const newAnnotations = this.annotations.map((pageAnnotations) =>
                pageAnnotations.map((annotation) => ({ ...annotation }))
            );

            for (let page = 0; page < this.annotations.length; page++) {
                for (let annot = 0; annot < this.annotations[page].length; annot++) {
                    //console.log('Annot: ' + annot);
                    //console.log(this.annotations[page][annot]);

                    if (this.annotations[page][annot].contents == groupid) {
                        //console.log('deleting')
                        let annotation = this.annotations[page][annot];
                        this.pdfFactory.deleteAnnotation(annotation.id, annotation.page);
                        this.removeFromHighlightList(annotation.rect, page)

                        // Update local annotations array by creating a new reference
                        newAnnotations[page] = newAnnotations[page].filter((a) => a.contents != groupid);
                    }
                }
            }

            // Update the original annotations array
            this.annotations = newAnnotations;
        },

        deleteAnnotations() {
            console.log(this.annotGroups)
            if (this.annotGroups.length > 0){
                let groupIDs = this.annotGroups
                console.log(groupIDs)
                for (let i in groupIDs) {
                    console.log(groupIDs[i])
                    this.deleteAnnotation(groupIDs[i]);
                }
            }
        },

        calculateCoordinatesForHtml() {
            // const scale2 = window.devicePixelRatio
            let rec = window.getSelection().getRangeAt(0).getBoundingClientRect()
            let ost = this.computePageOffset()
            let x_1 = (rec.x - ost.left)
            let y_1 = (rec.y - ost.top)
            let x_2 = (x_1 + rec.width)
            let y_2 = (y_1 + rec.height)
            return [x_1, y_1, x_2, y_2]
        },

        calculateCoordinatesForHtmlFromRect (rec) {
            let ost = this.computePageOffset()
            let x_1 = (rec.x - ost.left)
            let y_1 = (rec.y - ost.top)
            let x_2 = (x_1 + rec.width)
            let y_2 = (y_1 + rec.height)
            return [x_1, y_1, x_2, y_2]
        },

        calculateCoordinatesFromCustomRect(rect, actualPage) {
            const scale = this.pdfViewer._pages[actualPage].viewport.scale
            const scale2 = 1//window.devicePixelRatio
            const maxX = this.pdfViewer._pages[actualPage].viewport.width
            var maxY = this.pdfViewer._pages[actualPage].viewport.height
            maxY = maxY * scale2
            let rec = rect
            let x_1 = (rec[0] * (scale2 * scale))
            let y_1 = maxY - (rec[1] * (scale2 * scale))
            let x_2 = (rec[2] * (scale2 * scale))
            let y_2 = maxY - (rec[3] * (scale2 * scale))
            return [x_1, y_1, x_2, y_2]
        },

        drawAnnotation(x1, x2, y1, y2, page) {
            // await this.until(document.getElementById(page))
            let ctx = document.getElementById(page).getContext("2d")
            ctx.globalAlpha = 0.4
            ctx.fillStyle = this.color
            ctx.fillRect(
                x1,
                y1,
                (x2 - x1),
                (y2 - y1)
            )
            ctx.stroke()
        },

        drawStrokeAnnotation(x1, x2, y1, y2, page) {
            let ctx = document.getElementById(page).getContext("2d")
            ctx.strokeStyle = 'red'
            ctx.strokeRect(
                x1,
                y1,
                (x2 - x1),
                (y2 - y1)
            )
        },

        computePageOffset() {
            let pageId = "page" + 1
            let pg = document.getElementById(pageId).parentElement

            var rect = pg.getBoundingClientRect(), bodyElt = document.body;

            return {
                top: rect.top + bodyElt.scrollTop,
                left: rect.left + bodyElt.scrollLeft,
                height: rect.height,
                width: rect.width
            }
        },

        clear() {
            this.coordinates = []
            this.htmlCoords = []
            this.htmlcoordinates = []
            this.updateCoordinates()
        },

        calcCrossPageRect(page,start,end,coords) {
            const scale = this.pdfViewer._pages[page].viewport.scale
            const scale2 = 1//window.devicePixelRatio
            const widthDiff = (this.pagesPosition[start].width - this.pagesPosition[end].width) / (scale*scale2) / 2
            var coordsHTML = coords.map(x => x*scale*scale2)
            var viewPageHeight = this.pdfViewer._pages[page].viewport.height
            var pdfPageHeight = viewPageHeight/scale/scale2

            if (start == end) {
                var rectanglePDF = [
                        coords[0],
                        coords[1],
                        coords[2],
                        coords[3]
                ]
                var rectangleHTML = [
                    coordsHTML[0],
                    viewPageHeight - coordsHTML[1],
                    coordsHTML[2],
                    viewPageHeight - coordsHTML[3]
                ]
            }else {
                if (page == start) {
                    var rectanglePDF = [
                        coords[0],
                        coords[1],
                        coords[2] + widthDiff,
                        0
                    ]
                    var rectangleHTML = [
                        coordsHTML[0],
                        viewPageHeight - coordsHTML[1],
                        coordsHTML[2] + widthDiff*scale*scale2,
                        viewPageHeight
                    ]
                }
                if ((page != start) && (page != end)) {
                    var widthDiffStart = (this.pagesPosition[start].width - this.pagesPosition[page].width) / (scale*scale2) / 2
                    var widthDiffEnd = (this.pagesPosition[end].width - this.pagesPosition[page].width) / (scale*scale2) / 2


                    var rectanglePDF = [
                        coords[0] - widthDiffStart,
                        0,
                        coords[2] - widthDiffEnd,
                        pdfPageHeight
                    ]
                    var rectangleHTML = [
                        coordsHTML[0] - widthDiffStart*scale*scale2,
                        viewPageHeight,
                        coordsHTML[2] - widthDiffEnd*scale*scale2,
                        0
                    ]
                }
                if (page == end) {
                    var rectanglePDF = [
                        coords[0] - widthDiff,
                        pdfPageHeight,
                        coords[2],
                        coords[3]
                    ]
                    var rectangleHTML = [
                        coordsHTML[0] - widthDiff*scale*scale2,
                        0,
                        coordsHTML[2],
                        viewPageHeight - coordsHTML[3]
                    ]
                }
            }
            return [rectanglePDF, rectangleHTML]
        },

        processCoordinates(event) {
            let selectedColor = this.parseHexToRGB()

            // Calculate viewPoint coordinates
            let ost = this.computePageOffset();
            let x = (event.clientX - ost.left);
            let y = (event.clientY - ost.top);

            // Calculate pagePoint coordinates
            let pagePoint = this.calculateCoordinatesForPageFromPoint(x, y);

            // Get current page
            this.pageNumberCustomRect = this.lastCoordPage

            if (this.doSquare) {
                this.htmlcoordinates.push(pagePoint[0], pagePoint[1])
            }

            let x_y = this.pdfViewer._pages[this.pageNumberCustomRect].viewport.convertToPdfPoint(pagePoint[0], pagePoint[1])
            x = x_y[0]
            y = x_y[1]
            this.coordinates.push(x)
            this.coordinates.push(y)
            this.squarePages.push(this.lastCoordPage)

            this.updateCoordinates()

            if (this.doSquare) {
                // this.htmlcoordinates.push(x,y)
                // this.setStatus("Označte druhý bod anotácie")
                if (this.coordinates.length == 4) {
                    // this.setStatus("Štvorcová anotácia bola pridaná")
                    let groupid = this.getNewAnnotGroup()
                    console.log('got new groupid for square: ',groupid)

                    // TODO: this if can be problematic for multi-page square where pages are different width as the coordinates are for respective page
                    //       maybe we can check this after dividing the rectangle to page rectangles
                    if (!(this.coordinates.slice()[0] == this.coordinates.slice()[2]) & !(this.coordinates.slice()[1] == this.coordinates.slice()[3])) {
                        let start = (this.squarePages[0] < this.squarePages[1]) ? this.squarePages[0] : this.squarePages[1]
                        let end = (this.squarePages[0] < this.squarePages[1]) ? this.squarePages[1] : this.squarePages[0]
                        let flipped = start != this.squarePages[0] ? true : false

                        if (flipped) {
                            var coords = [this.coordinates[2],this.coordinates[3],this.coordinates[0],this.coordinates[1]]
                        } else {
                            var coords = this.coordinates
                        }

                        for(let page = start; page <= end; page++){

                            var [rectanglePDF,rectangleHTML] = this.calcCrossPageRect(page,start,end,coords)

                            this.pdfFactory.createSquareAnnotation({
                                page: page,
                                rect: rectanglePDF,
                                contents: groupid.toString(),
                                author: 'anonymize-square', // required so we know how much of the annot can be cut off
                                color: {r : selectedColor[0], g : selectedColor[1], b : selectedColor[2]},
                            })
                            this.markAnnot(rectangleHTML, page, groupid)
                        }

                    } else {
                        alert("Anonymizačné políčko je príliš malé, bude ignorované. Na označenie väčšej plochy drž stlačené lavé myšítko a potiahni.")
                    }
                    this.coordinates = []
                    this.squarePages = []
                    this.htmlcoordinates = []
                    this.doSquare = false
                    this.addAnnotGroup(groupid)
                    //this.setAnnotations()
                    this.refreshListOfAnnotations()
                    document.getElementById("viewer").style.userSelect = "auto"
                    document.getElementById("viewer").style.cursor = ""
                    //this.reRenderPdf(false)

                }
            }
        },

        calculateCoordinatesForPageFromPoint(x1,y1){
            let x,y = null;

            for(let i = 0; i < this.pagesPosition.length; i++){
                if((this.pagesPosition[i].y + this.pagesPosition[i].height) > y1){
                    this.lastCoordPage = i;
                    x = x1 + (this.pagesPosition[i].width - this.pagesPosition[0].width)/2;
                    y = y1 - this.pagesPosition[i].y;
                    return [x,y];
                }
            }
            return [x,y];
        },

        calculateCoordinatesForPageFromRect(rect){
            let ymax = Math.max(rect[3],rect[1]);
            let x1,x2,y1,y2 = null;

            for(let i = 0; i < this.pagesPosition.length; i++){
                if((this.pagesPosition[i].y + this.pagesPosition[i].height) > ymax){
                    this.lastCoordPage = i;
                    x1 = rect[0] + (this.pagesPosition[i].width - this.pagesPosition[0].width)/2;
                    x2 = rect[2] + (this.pagesPosition[i].width - this.pagesPosition[0].width)/2;
                    y1 = rect[1] - this.pagesPosition[i].y;
                    y2 = rect[3] - this.pagesPosition[i].y;
                    return [x1,y1,x2,y2]
                }
            }
            return [x1,y1,x2,y2];
        },

        loadAnnotGroups(){
            this.annotGroups = [];
            for (var page in this.annotations) {
                for (var annot in this.annotations[page]){
                    if (this.annotations[page][annot].author == 'anonymize-text' || this.annotations[page][annot].author == 'anonymize-square' || this.annotations[page][annot].author == 'anonymize-user-input' || this.annotations[page][annot].author == 'anonymize-minex'){
                        let groupId = this.getNewAnnotGroup()
                        if (this.annotations[page][annot].contents) {
                            groupId = parseInt(this.annotations[page][annot].contents)
                        }
                        else{
                            this.annotations[page][annot].contents = groupId.toString()
                        }
                        if (!this.annotGroups.includes(groupId)){
                            this.addAnnotGroup(groupId);
                        }
                    }
                }
            }
        },

        addAnnotGroup(groupid) {
            this.annotGroups.push(groupid)
            //console.log('added annot group: ' + groupid)
        },

        delAnnotGroup(groupid){
            this.annotGroups = this.annotGroups.filter(function(v) { return v != groupid })
            //console.log('delete annot group: ' + groupid)
        },

        getNewAnnotGroup(suggested=false) {
            let newid = undefined
            if (suggested) {
                newid = this.suggestAnnotIndexOffset
                let groups = this.annotGroups.filter(id => id >= this.suggestAnnotIndexOffset)
                if (groups.length > 0){
                    newid = Math.max(...groups) + 1;
                }
            } else {
                newid = 0;
                let groups = this.annotGroups.filter(id => id < this.suggestAnnotIndexOffset)
                if (groups.length > 0){
                    newid = Math.max(...groups) + 1;
                }
            }

            //console.log("getting new group id: " + newid);
            return newid
        },

        addHighlightAnnotation() {
            const scale2 = window.devicePixelRatio
            let selectedColor = this.parseHexToRGB()
            let annotRect = []
            let viewRect = []
            let pageRect = []
            let groupid = this.getNewAnnotGroup()

            // Rectangles of selected text
            let rects = this.getSelectionBoxes()

            if (rects.length == 0 || (rects.length == 1 && (rects[0].width == 0 || rects[0].height == 0))){
                this.closeOverlay();
                return;
            }

            //console.log(rects)

            for (let i = 0; i < rects.length; i++) {
                // Convert html view coordinates to viewContainer coordinates
                viewRect = this.calculateCoordinatesForHtmlFromRect(rects[i])

                //console.log(viewRect)

                // Convert viewContainer coordinates to rendered page coordinates
                pageRect = this.calculateCoordinatesForPageFromRect(viewRect)

                //console.log(pageRect)

                // Get actual page of rectangle
                let actualPage = this.lastCoordPage

                // Convert rendered page coordinates to PDF page coordinates
                annotRect = this.selectionCoordinates(pageRect[0],pageRect[1],pageRect[2],pageRect[3],actualPage)

                //console.log(annotRect)

                // Check if selection is really in the PDF page
                let width = this.pdfViewer._pages[actualPage].viewport.width
                let height = this.pdfViewer._pages[actualPage].viewport.height
                if (annotRect[0] < 0 || annotRect[1] < 0 || annotRect[2] < 0 || annotRect[3] < 0) {
                    //console.log('less than 0')
                    continue;
                }
                if (annotRect[0] > width || annotRect[1] > height || annotRect[2] > width || annotRect[3] > height) {
                    //console.log('more than height or width')
                    continue;
                }

                // Check if the selection isnt already annotated
                if (this.alreadyHighlighted(annotRect,actualPage)){
                    //console.log('already highlighted')
                    continue;
                }

                // Add the annotation to our annot list
                this.addToHighlightList(annotRect, actualPage);

                // Add the annotation to pdf
                this.pdfFactory.createHighlightAnnotation({
                    page: actualPage,
                    rect: annotRect,
                    contents: groupid.toString(),
                    author: 'anonymize-text', // required so we know how much of the annot can be cut off
                    color: {r : selectedColor[0], g : selectedColor[1], b : selectedColor[2]},
                    opacity: 0.5
                    //quadPoints: quads
                })

                // Draw the annotation
                this.markAnnot(pageRect, actualPage, groupid)
                //this.drawAnnotation(pageRect[0] * scale2, pageRect[2] * scale2, pageRect[1] * scale2, pageRect[3] * scale2, "page" + (actualPage  + 1))
            }

            this.addAnnotGroup(groupid)
            this.coordinates = []
            this.updateCoordinates()
            //this.setAnnotations()
            this.refreshListOfAnnotations()
            this.doSquare = false
        },

        addSquareAnnotation(event = undefined) {
            if (!this.doSquare) {
                // this.setStatus("Označte prvý bod anotácie")
                this.coordinates = []
                this.squarePages = []
                this.doSquare = true
                document.getElementById("viewer").style.cursor = "crosshair"
                document.getElementById("viewer").style.userSelect = "none"
            }
        },

        calculatePoints(event) {
            this.htmlCoords = this.calculateCoordinatesForHtml()
        },

        alreadyHighlighted(selRect, page){
            let alreadySelected = this.textSelAnnots
            if(alreadySelected.length === 0) return false;

            for (let i=0 ; i < alreadySelected.length; i++){
                let a = alreadySelected[i]
                if(a.page != page) continue;
                if(a.rectJSON === JSON.stringify(selRect)) return true;
            }
            return false;
        },

        addToHighlightList(rect, page){
            let annot = {
                'page' : page,
                'rectJSON' : JSON.stringify(rect)
            }
            this.textSelAnnots.push(annot);
        },

        removeFromHighlightList(rect, page){
            let alreadySelected = this.textSelAnnots
            if(alreadySelected.length === 0) {
                console.error("Attempting to remove highlight from empty highlight list");
                return;
            }

            for (let i=0 ; i < alreadySelected.length; i++){
                let a = alreadySelected[i]
                if(a.page != page) continue;
                if(a.rectJSON === JSON.stringify(rect)) {
                    // Remove the highlight from the highlights list
                    this.textSelAnnots.splice(i, 1);
                    break;
                }
            }
        },

        writeSuggestedAnnotsToPDF(pdffactory=null){
            if(!pdffactory){
                pdffactory = this.pdfFactory;
            }

            let color = this.parseHexToRGB()
            for (let key in this.checkedAnnots) {
                const scale = this.pdfViewer._pages[this.checkedAnnots[key].page].viewport.scale
                const scale2 = 1//window.devicePixelRatio
                var maxY = this.pdfViewer._pages[this.checkedAnnots[key].page].viewport.height
                maxY = maxY / scale

                if ((typeof (this.checkedAnnots[key].newRect) !== 'undefined')) {

                    pdffactory.createHighlightAnnotation(
                        this.checkedAnnots[key].page,
                        [(this.checkedAnnots[key].newRect[0] / (scale2 * scale)),
                        (maxY - (this.checkedAnnots[key].newRect[1] / (scale2 * scale))),
                        (this.checkedAnnots[key].newRect[2] / (scale2 * scale)),
                        (maxY - (this.checkedAnnots[key].newRect[3] / (scale2 * scale)))],
                        this.checkedAnnots[key].id.toString(),
                        "anonymize-minex",
                        { r : color[0], g : color[1], b : color[2] }
                    )
                }
                if (typeof (this.checkedAnnots[key].newRects) !== 'undefined') {

                    for (let rect in this.checkedAnnots[key].newRects) {
                        pdffactory.createHighlightAnnotation(
                            this.checkedAnnots[key].page,
                            [(this.checkedAnnots[key].newRects[rect][0] / (scale2 * scale)),
                            (maxY - (this.checkedAnnots[key].newRects[rect][1] / (scale2 * scale))),
                            (this.checkedAnnots[key].newRects[rect][2] / (scale2 * scale)),
                            (maxY - (this.checkedAnnots[key].newRects[rect][3] / (scale2 * scale)))],
                            this.checkedAnnots[key].id.toString(),
                            "anonymize-minex",
                            { r : color[0], g : color[1], b : color[2] }
                        )
                    }
                }
            }

            return pdffactory
        },

        pushPdf() {
            this.isPushingPdf = true;
            // send pdf to backend
            // create annotation for checked checkboxes
            this.showOverlay(this.$t("LoadingMessages.downloadLoading"));
            //this.writeSuggestedAnnotsToPDF()

            let data = this.pdfFactory.write()
            console.log(data);
            let formData = new FormData()
            let dataBlob = new Blob([data], { type: 'application/pdf' })
            formData.append('file', dataBlob)
            formData.append('fileName', this.fileId)
            console.log(formData);
            setTimeout(() => {
                this.axios.post(
                    EnvProvider.value('API_URL') + this.$root.API_ANON,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'x-csrf-token': this.cookies.get('csrfToken')
                        }
                    }
                ).then((resp) => {
                    if (resp.status === 200) {
                        this.closeOverlay();
                        this.$store.commit('pushMsg', { type: 'ok', text: 'Dokument bol úspešne zanonymizovaný' })
                        this.$router.push({
                            name: 'DownloadView'
                        })
                    } else {
                        this.$store.commit('pushMsg', { type: 'error', text: 'Dokument sa nepodarilo zanonymizovať' })
                    }
                })
            }, 500);

        },

        download() {
            console.log(this.pdfFactory)
            this.pdfFactory.download(this.filename)
            // if (Object.keys(this.checkedAnnots).length == 0){
            //     this.pdfFactory.download(this.filename)
            // }else{
            //     // create temp pdf factory with selected suggested annots
            //     let tempPdfFactory = new AnnotationFactory(this.pdfFactory.data)
            //     let annotations = this.pdfFactory.getAnnotations()
            //     annotations.then(value => {
            //         let annots = []
            //         for (var page in value) {
            //             for (var annot in value[page]){
            //                 if(value[page][annot].id.includes('pdfAnnotate')){
            //                     annots.push(value[page][annot])
            //                 }
            //             }
            //         }
            //         tempPdfFactory.annotations = annots
            //         tempPdfFactory.parser = this.pdfFactory.parser
            //         if ('toDelete' in tempPdfFactory) {
            //             tempPdfFactory.toDelete = this.pdfFactory.toDelete
            //         }
            //         tempPdfFactory = this.writeSuggestedAnnotsToPDF(tempPdfFactory)

            //         // download pdf with all annots
            //         tempPdfFactory.download(this.filename);
            //     })
            // }
        },
    },
}
</script>

<style>
.activeButton {
    background-color: rgb(112, 200, 230);
}</style>

<style lang="scss" scoped src="@/assets/css/Pdfviewer.scss"/>
